import { FadeLoader } from "react-spinners";
import { useStickersLibrary } from "../../../Context/useStickerLibrary";
import { useRef, useEffect } from "react";

type Props = {
    className: string;
    closeMenu: () => void;
    buttonRef: React.RefObject<HTMLButtonElement>;
};
const OthersMobile = ({ className, closeMenu, buttonRef }: Props) => {
    const { libraryStickers, handleSelectStickerToEdit } = useStickersLibrary();

    const otherDiv = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (otherDiv.current && !otherDiv.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                closeMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={otherDiv} className={`${className} z-50 max-h-[509px]`}>
            <div className="flex justify-between w-full py-2 px-3">
                <p className="font-black text-lg text-text_light_secondary dark:text-white  uppercase">Sparky</p>
                <svg onClick={closeMenu} width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-text_light_secondary dark:text-white">
                    <path d="M11 1L1 11M1 1L11 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className={`grid grid-cols-3 gap-3 ${libraryStickers && libraryStickers.length > 0 ? "overflow-auto" : ""}`}>
                {libraryStickers && libraryStickers.length > 0 ? (
                    libraryStickers
                        .filter((s) => s.library_type === "OTHER")
                        .map((template) => (
                            <div
                                onClick={() => {
                                    handleSelectStickerToEdit(template);
                                    closeMenu();
                                }}
                                key={template.id}
                                className="flex justify-center bg-white dark:bg-bg_dark_secondary items-center rounded-lg h-[124px]"
                            >
                                <img src={`${template.base64Sticker}`} className="w-full h-full object-contain rounded-lg" alt="" />
                            </div>
                        ))
                ) : (
                    <div className="flex w-full items-center justify-center min-w-[396px]">
                        <FadeLoader color="#F67D52" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default OthersMobile;
