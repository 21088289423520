import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import FullPageLoader from "./FullPageLoader";
import { http2 } from "../../Lib/axiosConfig";

const TwitterCallback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const finalImageBase64 = localStorage.getItem("finalImage");
    const status = localStorage.getItem("finalMessage");
    useEffect(() => {
        const fetchAccessTokenAndShare = async () => {
            const query = new URLSearchParams(location.search);
            const oauth_token = query.get("oauth_token");
            const oauth_verifier = query.get("oauth_verifier");

            if (oauth_token && oauth_verifier) {
                try {
                    const response = await http2.post("auth/twitter/access-token", {
                        oauth_token,
                        oauth_verifier,
                    });

                    const oauth_token_secret = response.data.data.oauth_token_secret;
                    const oauth_token_get = response.data.data.oauth_token;
                    debugger;
                    await http2.post("auth/twitter/share-to-twitter", {
                        imageBase64: finalImageBase64,
                        status,
                        oauth_token: oauth_token_get,
                        oauth_token_secret: oauth_token_secret,
                    });
                    toast.success("Successfully shared on Twitter.");
                    console.log("Successfully shared on Twitter");
                    localStorage.removeItem("finalImage");
                    localStorage.removeItem("finalMessage");
                    if (window.opener) {
                        // If the window was opened as a popup, close it
                        window.close();
                    } else {
                        // If not a popup, navigate back to the homepage
                        navigate("/");
                    }
                } catch (error) {
                    console.error("Error sharing on Twitter:", error);
                }
            } else {
                console.error("Twitter authentication failed.");
            }
        };

        fetchAccessTokenAndShare();
    }, [location, navigate, finalImageBase64, status]);

    return <FullPageLoader />;
};

export default TwitterCallback;
