import { Page } from "./ShareModal";
import { ArrowIcon, XIcon, InstagramIcon, ArrowIcon2 } from "../../Common/Icons";
import ShareToX from "./ShareToX";
import { useState } from "react";

type Props = {
    setCurrentPage: (page: Page) => void;
};
const ShareOnSocial = ({ setCurrentPage }: Props) => {
    const [shareToX, setShareToX] = useState<boolean>(true);
    const handleClickOnShareToX = () => {
        setShareToX(!shareToX);
    };

    const handleCancelShareToX = () => {
        setShareToX(false);
    };

    return (
        <div className="flex flex-col gap-8 w-full">
            <div className="flex justify-start items-center gap-3 " aria-label="header">
                <p className="uppercase font-bold text-lg text-black dark:text-white text-center">SHARE ON X</p>
            </div>
            <div className="flex flex-col gap-3 " aria-label="download">
                {shareToX === false ? (
                    <>
                        <button onClick={handleClickOnShareToX} className="flex justify-start gap-2 rounded-lg py-2 px-4 text-white bg-button_primary w-full leading-7 uppercase">
                            <XIcon color="red" /> share on x
                        </button>
                    </>
                ) : (
                    <ShareToX />
                )}
            </div>
        </div>
    );
};

export default ShareOnSocial;
