import { createContext, useState } from "react";
import React from "react";
import { TextBoxes } from "../Models/Library";
import { AvailableFonts } from "../Components/MainArea/ControlPanel/TextEditor/FontsDropDown";

type TextLibraryContextType = {
    // colorPicked: string;
    textBoxes: TextBoxes[];
    activeTextBoxId: string;
    // setColorPicker: (color: string) => void;
    addText: (model: TextBoxes) => void;
    deleteText: (index: number) => void;
    changeSetActiveTextBoxId: (id: string) => void;
    getIndexOfTextBox: () => number;
    reset: () => void;
    handleSetSelectedFont: (font: AvailableFonts) => void;
    selectedFont: AvailableFonts;
    handleSetTextStyle: (font: TextBoxes) => void;
    currentTextStyle: TextBoxes | null;
    handleSetTextBoxes: (textBoxes: TextBoxes[]) => void;
    changeTextStyle: { isChange: boolean; changeCount: number };
};

type Props = { children: React.ReactNode };

const TextLibraryContext = createContext<TextLibraryContextType>({} as TextLibraryContextType);

export const TextsLibraryProvider = ({ children }: Props) => {
    // const [colorPicked, setColorPicked] = useState<string>("blue");

    const [textBoxes, setTextBoxes] = useState<TextBoxes[]>([]);

    const [currentTextStyle, setCurrentTextStyle] = useState<TextBoxes | null>(null);

    const [activeTextBoxId, setActiveTextBoxId] = useState<string>("");

    const [changeTextStyle, setChangeTextStyle] = useState<{ isChange: boolean; changeCount: number }>({ isChange: false, changeCount: 0 });

    const [selectedFont, setSelectedFont] = useState<AvailableFonts>(AvailableFonts.MONTSERRAT);

    const handleSetTextBoxes = (textBoxes: TextBoxes[]) => {
        setTextBoxes(textBoxes);
    };

    // const setColorPicker = (color: string) => {
    //     setColorPicked(color);

    //     setTextBoxes((prev) => prev.map((textBox) => (textBox.id === activeTextBoxId ? { ...textBox, color: color } : textBox)));
    // };

    const handleSetTextStyle = (model: TextBoxes) => {
        setTextBoxes((prev) =>
            prev.map((textBox) =>
                textBox.id === activeTextBoxId
                    ? {
                          ...textBox,
                          font: model.font,
                          bold: model.bold,
                          color: model.color,
                          italic: model.italic,
                          underline: model.underline,
                          align: model.align,
                          stroke: model.stroke,
                          strokeColor: model.strokeColor,
                          transform: textBox.transform,
                      }
                    : textBox
            )
        );
        setChangeTextStyle({ isChange: true, changeCount: changeTextStyle.changeCount + 1 });
    };

    const changeSetActiveTextBoxId = (id: string) => {
        setActiveTextBoxId(id);
        setCurrentTextStyle(textBoxes.find((t) => t.id === id) ?? null);
    };

    const addText = (model: TextBoxes) => {
        setTextBoxes((prevText) => [...prevText, model]);
    };

    const deleteText = (index: number) => {
        setTextBoxes((prevTextBoxes) => prevTextBoxes.filter((_, i) => i !== index));
        // setTextBoxes((prevText) => prevText.filter((d) => d.id !== activeTextBoxId));
        setActiveTextBoxId("");
        setCurrentTextStyle(null);
    };

    const getIndexOfTextBox = () => {
        const index = textBoxes.findIndex((textBox) => textBox.id === activeTextBoxId);
        return index;
    };

    const reset = () => {
        setActiveTextBoxId("");
        setTextBoxes([]);
        setChangeTextStyle({ isChange: false, changeCount: 0 });
        setCurrentTextStyle(null);
    };

    const handleSetSelectedFont = (font: AvailableFonts) => {
        setSelectedFont(font);
    };

    return (
        <TextLibraryContext.Provider
            value={{
                // colorPicked,
                textBoxes,
                activeTextBoxId,
                // setColorPicker,
                addText,
                deleteText,
                changeSetActiveTextBoxId,
                getIndexOfTextBox,
                reset,
                handleSetSelectedFont,
                selectedFont,
                handleSetTextStyle,
                currentTextStyle,
                handleSetTextBoxes,
                changeTextStyle,
            }}
        >
            {children}
        </TextLibraryContext.Provider>
    );
};

export const useTextsLibrary = () => React.useContext(TextLibraryContext);
