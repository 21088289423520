import { TwitterBanner, MobileWallpaper, DesktopWallpaper, SquareIcon } from "../../../Common/Icons";

export enum CanvasSizeType {
    TWITTER, //1500 * 500
    MOBILE, // 640 * 960
    DESKTOP, // 1920 * 1080
    SQUARE, // 1080 * 1080
    NOT_SET, // 1080 * 1080
}

export interface ICanvasSizeOption {
    type: CanvasSizeType;
    titleOnControlPanel: JSX.Element;
    titleOnControlPanelMobile: JSX.Element;
    icon: JSX.Element;
    title: string;
    secondaryTitle: string;
    pxWidth: number;
    pxHeight: number;
}

export const CanvasSizeOptions: ICanvasSizeOption[] = [
    {
        type: CanvasSizeType.NOT_SET,
        titleOnControlPanel: <>Select Size</>,
        titleOnControlPanelMobile: <>Select Size</>,
        icon: <></>,
        title: "",
        secondaryTitle: "1080 * 1080",
        pxWidth: 550,
        pxHeight: 550,
    },
    {
        type: CanvasSizeType.TWITTER,
        titleOnControlPanel: (
            <>
                <TwitterBanner color="text-[#353F5C] dark:text-text_dark_primary" width="20" height="24" />
                Twitter Banner
            </>
        ),
        titleOnControlPanelMobile: (
            <>
                <TwitterBanner color="text-[#353F5C] dark:text-text_dark_primary" width="20" height="24" />X Banner
            </>
        ),
        icon: <TwitterBanner />,
        title: "Twitter Banner",
        secondaryTitle: "1500 * 500",
        pxWidth: 956,
        pxHeight: 319,
    },
    {
        type: CanvasSizeType.MOBILE,
        titleOnControlPanel: (
            <>
                <MobileWallpaper color="text-[#353F5C] dark:text-text_dark_primary" width="20" height="24" />
                Mobile Wallpaper
            </>
        ),
        titleOnControlPanelMobile: (
            <>
                <MobileWallpaper color="text-[#353F5C] dark:text-text_dark_primary" width="20" height="24" />
                Mobile
            </>
        ),
        icon: <MobileWallpaper />,
        title: "Mobile Wallpaper",
        secondaryTitle: "640 * 960",
        pxWidth: 309,
        pxHeight: 550,
    },
    {
        type: CanvasSizeType.DESKTOP,
        titleOnControlPanel: (
            <>
                <DesktopWallpaper color="text-[#353F5C] dark:text-text_dark_primary" width="24" height="24" />
                Desktop Wallpaper
            </>
        ),
        titleOnControlPanelMobile: (
            <>
                <DesktopWallpaper color="text-[#353F5C] dark:text-text_dark_primary" width="20" height="24" />
                Desktop
            </>
        ),
        icon: <DesktopWallpaper />,
        title: "Desktop Wallpaper",
        secondaryTitle: "1920 * 1080",
        pxWidth: 956,
        pxHeight: 537,
    },
    {
        type: CanvasSizeType.SQUARE,
        titleOnControlPanel: (
            <>
                <SquareIcon color="text-[#353F5C] dark:text-text_dark_primary" />
                Square
            </>
        ),
        titleOnControlPanelMobile: (
            <>
                <SquareIcon color="text-[#353F5C] dark:text-text_dark_primary" />
                Square
            </>
        ),
        icon: <SquareIcon color="text-white" width="16" height="16" />,
        title: "Square",
        secondaryTitle: "1080 * 1080",
        pxWidth: 550,
        pxHeight: 550,
    },
];
