import { FadeLoader } from "react-spinners";
import { useTemplateLibrary } from "../../../Context/useTemplateLibrary";
import { LeftSideSection } from "../LeftSide";
import { useRef, useState, useEffect } from "react";

type Props = {
    className: string;
    show: boolean;
    handleViewAllClick: (page: LeftSideSection) => void;
};

const Memes = ({ className, show, handleViewAllClick }: Props) => {
    const { templateImages, handleSelectImageToEdit } = useTemplateLibrary();

    const templateRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number | undefined>(undefined);
    useEffect(() => {
        const updateHeight = () => {
            const adjacentElement = document.getElementById("adjacent-component-id");
            if (adjacentElement && templateRef.current) {
                // setHeight(adjacentElement.offsetHeight - 62);
                setHeight(window.innerHeight - 150);
            }
        };

        updateHeight();

        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, [templateImages, show]);
    return (
        <div className={`${className}`} ref={templateRef} style={{ height: show === true ? height || "auto" : "auto" }}>
            <div className="flex justify-between cursor-pointer" onClick={() => handleViewAllClick(show ? LeftSideSection.ALL : LeftSideSection.MEMES)}>
                <div className="flex items-center gap-2">
                    {show && (
                        <div className="rotate-180">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-text_light_primary dark:text-text_dark_primary">
                                <path d="M1 8H15M15 8L8 1M15 8L8 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    )}
                    <p className="uppercase font-medium text-black dark:text-white">Memes</p>
                </div>
                {!show && (
                    <div className="flex items-center gap-2">
                        <p className="uppercase text-sm font-medium text-text_light_primary dark:text-text_dark_primary">View all</p>
                        <div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-text_light_primary dark:text-text_dark_primary">
                                <path d="M1 8H15M15 8L8 1M15 8L8 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                )}
            </div>

            {templateImages && templateImages.length > 0 ? (
                <div className={`${show ? "overflow-y-auto overflow-x-hidden pr-1 pb-3" : ""}`} style={{ height: show === true ? height || "auto" : "auto" }}>
                    <div className={`grid ${!show ? `grid-cols-2 desktop:grid-cols-3 ` : `grid-cols-2 grid-rows-2`} gap-4 max-w-[396px]`}>
                        {show
                            ? templateImages
                                  .filter((t) => t.library_type === "MEMES")
                                  .map((template) => (
                                      <div onClick={() => handleSelectImageToEdit(template, true)} key={template.id} className="flex justify-center items-center rounded-lg h-[123px] ma-w-[186px]">
                                          <img src={`${template.base64Thumbnail}`} className="w-full h-full object-cover rounded-lg" alt="" />
                                      </div>
                                  ))
                            : templateImages
                                  .filter((t) => t.library_type === "MEMES")
                                  .slice(0, 6)
                                  .map((template) => (
                                      <div onClick={() => handleSelectImageToEdit(template, true)} key={template.id} className="flex justify-center items-center rounded-lg h-[68px] w-[124px]">
                                          <img src={`${template.base64Thumbnail}`} className="w-full h-full object-cover rounded-lg" alt="" />
                                      </div>
                                  ))}
                    </div>
                </div>
            ) : (
                <div className="flex w-full items-center justify-center min-w-[396px]">
                    <FadeLoader color="#F67D52" />
                </div>
            )}
        </div>
    );
};

export default Memes;
