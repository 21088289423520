import { useTemplateLibrary } from "../../../Context/useTemplateLibrary";
import { useStickersLibrary } from "../../../Context/useStickerLibrary";
import { useEffect, useRef, useState } from "react";
import { CanvasSizeOptions } from "./CanvasSize/CanvasSizeData";
import CanvasSize from "./CanvasSize/CanvasSize";
import { useCanvasSize } from "../../../Context/useCanvasSize";
import { useTextsLibrary } from "../../../Context/useTextLibrary";
import { AddText, ArrowIcon, Delete, DownloadIcon, Flip, MouseCursor, MoveCursor, RedoIcon, ResetIcon, ResetZoom, TextIcon, UndoIcon, ZoomIn, ZoomOut } from "../../Common/Icons";
import TextEditor from "./TextEditor/TextEditor";
import { useGlobalActions } from "../../../Context/useGlobalActionsContext";
import ZoomSlider from "./ZoomSlider/ZoomSlider";

type Props = {
    handleFlipX: () => void;
    handleFlipY: () => void;
    handleText: () => void;
    handleDeleteClick: () => void;
    undo: () => void;
    redo: () => void;
    zoomIn: () => void;
    zoomOut: () => void;
};

const ControlPanel = ({ handleFlipX, handleFlipY, handleText, handleDeleteClick, undo, redo, zoomIn, zoomOut }: Props) => {
    const { saveResultImage, handleSelectImageToEdit } = useTemplateLibrary();

    const { handleSelectStickerToEdit } = useStickersLibrary();
    const { reset } = useTextsLibrary();
    const { selectedCanvasSize, handleSetSelectedCanvasSize } = useCanvasSize();
    const [showTextEditor, setShowTextEditor] = useState(false);
    const [showZoomSlider, setShowZoomSlider] = useState(false);
    const [showCanvasSize, setShowCanvasSize] = useState(false);
    const { handleSetScale, scale, handleEnableDisableMovement, isMovable, saveImage, showLoader } = useGlobalActions();
    const canvasSizeRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const textEditorRef = useRef<HTMLDivElement>(null);
    const zoomSliderRef = useRef<HTMLDivElement>(null);
    const buttonTexEditorRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && (event.key === "+" || event.key === "=")) {
                event.preventDefault();
                zoomIn();
            }

            if (event.ctrlKey && event.key === "-") {
                event.preventDefault();
                zoomOut();
            }

            if (event.ctrlKey && event.key === "0") {
                event.preventDefault();
                handleSetScale(1);
            }

            if (event.ctrlKey && event.code === "KeyZ") {
                event.preventDefault();
                undo();
            }

            if (event.ctrlKey && event.code === "KeyY") {
                event.preventDefault();
                redo();
            }

            if (event.code === "Space") {
                if (scale > 1) {
                    handleEnableDisableMovement(true);
                }
            }
        };
        // set space
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.code === "Space") {
                if (scale > 1) {
                    handleEnableDisableMovement(false);
                }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [zoomIn, zoomOut, handleSetScale, undo, redo, handleEnableDisableMovement]);

    const handleReset = () => {
        saveResultImage(null);
        handleSelectImageToEdit(null, false);
        handleSelectStickerToEdit(null);
        handleSetSelectedCanvasSize(CanvasSizeOptions[0]);
        handleSetScale(1);
        handleEnableDisableMovement(false);
        reset();
    };

    const handleShowTextEditor = () => {
        setShowTextEditor(!showTextEditor);
    };

    const closeTextEditor = () => {
        setShowTextEditor(false);
    };

    const handleShowCanvasSize = () => {
        setShowCanvasSize(!showCanvasSize);
    };

    const closeCanvasSize = () => {
        setShowCanvasSize(false);
    };

    useEffect(() => {
        if (scale != 1) {
            setShowZoomSlider(true);
        } else {
            setShowZoomSlider(false);
        }
    }, [scale]);

    return (
        <div className="hidden xl:flex flex-col gap-2 ">
            <div className={`hidden lg:flex justify-between items-center gap-2 bg-bg_light_third dark:bg-bg_dark_secondary px-4 rounded-b-lg min-h-14 w-full`}>
                <div className="flex items-center gap-6 ">
                    <div className="flex gap-5">
                        <button onClick={undo} disabled={showLoader} title="Undo" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                            <UndoIcon color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                        <button onClick={redo} disabled={showLoader} title="Redo" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                            <RedoIcon color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                    </div>
                    <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />

                    <div className="flex gap-2">
                        <button onClick={zoomOut} disabled={showLoader} title="Zoom Out Background Image" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                            <ZoomOut color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                        <button onClick={zoomIn} disabled={showLoader} title="Zoom In Background Image" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                            <ZoomIn color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                    </div>
                    <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />

                    <div className="relative flex gap-5">
                        <button onClick={handleText} title="Add Text" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                            <AddText color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>

                        <button
                            title="Text Style"
                            ref={buttonTexEditorRef}
                            onClick={handleShowTextEditor}
                            className="flex items-center gap-1 hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1"
                        >
                            <TextIcon color="text-text_light_primary dark:text-text_dark_primary" />
                            <ArrowIcon
                                width="14"
                                height="8"
                                color={` transition-transform transform ${showTextEditor ? "rotate-180" : "rotate-0"}  text-text_light_primary dark:text-text_dark_primary`}
                            />
                        </button>
                        <div ref={textEditorRef} className={`absolute top-10 z-50 ${showTextEditor ? "" : "hidden"}`}>
                            <TextEditor buttonRef={buttonTexEditorRef} closeTextEditor={closeTextEditor} />
                        </div>
                    </div>
                    <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />

                    <div className="flex gap-5">
                        <button onClick={handleFlipX} title="Flip X" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                            <Flip color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                        <button onClick={handleFlipY} title="Flip Y" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1 rotate-90">
                            <Flip color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                    </div>

                    <div className="relative">
                        <button
                            ref={buttonRef}
                            onClick={handleShowCanvasSize}
                            title="Choose Canvas Size"
                            disabled={showLoader}
                            className="flex items-center gap-2 font-medium dark:text-text_dark_primary hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1"
                        >
                            {CanvasSizeOptions.find((c) => c.type === selectedCanvasSize.type)?.titleOnControlPanelMobile}
                        </button>
                        <div ref={canvasSizeRef} className={`absolute top-10 right-0 z-50 ${showCanvasSize ? "" : "hidden"} shadow-canvas_size_menu`}>
                            <CanvasSize buttonRef={buttonRef} closeCanvasSize={closeCanvasSize} />
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-6">
                    <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />
                    <div className="flex gap-5">
                        <button
                            onClick={saveImage}
                            title="Download"
                            disabled={showLoader}
                            className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] dark:text-text_dark_primary rounded-lg p-1 uppercase font-medium text-text_light_primary"
                        >
                            <DownloadIcon color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                        <button
                            onClick={handleReset}
                            title="Reset All"
                            disabled={showLoader}
                            className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] dark:text-text_dark_primary rounded-lg p-1 uppercase font-medium text-text_light_primary"
                        >
                            <ResetIcon color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                        <button onClick={handleDeleteClick} title="Delete Selected Item" className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                            <Delete color="text-text_light_primary dark:text-text_dark_primary" />
                        </button>
                    </div>
                </div>
            </div>
            <div ref={zoomSliderRef} className={`hidden lg:flex xl:items-center xl:gap-2 w-fit self-end py-2 px-4 rounded-lg z-50 bg-bg_light_third dark:bg-bg_dark_secondary`}>
                <button
                    onClick={() => {
                        handleSetScale(1);
                    }}
                    title="Reset Canvas Zoom"
                    className={`${scale !== 1 ? "hover:bg-[#C3C1C8] dark:hover:bg-[#63697B]" : ""} rounded-lg p-1 `}
                    disabled={scale === 1}
                >
                    <ResetZoom color={`${scale === 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                </button>
                <ZoomSlider />

                <button
                    onClick={() => {
                        handleEnableDisableMovement(!isMovable);
                    }}
                    title="Move canvas"
                    className={`${!isMovable && scale <= 1 ? "hover:bg-[#C3C1C8] dark:hover:bg-[#63697B]" : ""} rounded-lg p-1 `}
                    disabled={!isMovable && scale <= 1}
                >
                    {!isMovable ? (
                        <MoveCursor color={`${!isMovable && scale <= 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                    ) : (
                        <MouseCursor color={`${!isMovable && scale <= 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                    )}
                </button>
            </div>
        </div>
    );
};

export default ControlPanel;
