import html2canvas from "html2canvas";
import MoveableHelper from "moveable-helper";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Moveable, { OnDragEnd } from "react-moveable";
import { ISticker, useStickersLibrary } from "../../../Context/useStickerLibrary";
import { imageHistory, useTemplateLibrary } from "../../../Context/useTemplateLibrary";
import { useTextsLibrary } from "../../../Context/useTextLibrary";
import { useCanvasSize } from "../../../Context/useCanvasSize";
import { useGlobalActions } from "../../../Context/useGlobalActionsContext";
import { CanvasSizeOptions, CanvasSizeType, ICanvasSizeOption } from "../ControlPanel/CanvasSize/CanvasSizeData";
import { AvailableFonts } from "../ControlPanel/TextEditor/FontsDropDown";
import { TextBoxes } from "../../../Models/Library";
import { v4 as uuid } from "uuid";

type Props = {
    className?: string;
    // imageBase64: string;
    stickerBase64: string | null;
};

export interface EditImageAriaHandles {
    deleteSticker: () => void;
    saveImage: () => void;
    handleFlipX: () => void;
    handleFlipY: () => void;
    handleText: () => void;
    undo: () => void;
    redo: () => void;
    handleZoomIn: () => void;
    handleZoomOut: () => void;
}

const EditImageAria = forwardRef<EditImageAriaHandles, Props>(({ className, stickerBase64 }: Props, ref) => {
    const moveableTextBoxRef = useRef(null);
    const { setSaveImage, downloadType, setShareOnX, scale, handleSetScale, handleSeShowLoader, isMovable, showLoader } = useGlobalActions();
    const [imageScale, setImageScale] = useState<number>(1);
    const { saveResultImage, handleSetFinalImageBase64, selectedImageToEdit, handleSelectImageToEdit } = useTemplateLibrary();
    const { handleSelectStickerToEdit } = useStickersLibrary();
    const { textBoxes, changeSetActiveTextBoxId, deleteText, addText, handleSetTextBoxes, activeTextBoxId, changeTextStyle } = useTextsLibrary();
    const { selectedCanvasSize, handleSetSelectedCanvasSizeAsync } = useCanvasSize();
    const [showController, setShowController] = useState(false);
    const [stickerBase64s, setStickerBase64s] = useState<ISticker[]>([]);
    const [stickerSelectedId, setStickerSelectedId] = useState<number>(0);
    const [selectedTargetSticker, setSelectedTargetSticker] = useState<HTMLElement | null>(null);
    const [selectedTargetText, setSelectedTargetText] = useState<HTMLElement | null>(null);
    const targetRefStickers = useRef<(HTMLElement | null)[]>([]);
    const targetRefTexts = useRef<(HTMLElement | null)[]>([]);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [x, setX] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [desktop, setDesktop] = useState(false);
    const [square, setSquare] = useState(false);
    const [canvasPosition, setCanvasPosition] = useState({ x: 0, y: 0 });
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [backgroundImageHistory, setBackgroundImageHistory] = useState<imageHistory>({ scale: 1, transform: "scale(1)" });
    const [history, setHistory] = useState<{ stickers: ISticker[]; textBoxes: TextBoxes[]; backgroundImage: imageHistory }[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(-1);

    const movableDivision = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (changeTextStyle.isChange) {
            addToHistory(stickerBase64s, textBoxes, imageScale, backgroundImageHistory.transform);
        } else {
        }
    }, [changeTextStyle]);

    useEffect(() => {
        setX(selectedCanvasSize.type === CanvasSizeType.TWITTER);
        setMobile(selectedCanvasSize.type === CanvasSizeType.MOBILE);
        setDesktop(selectedCanvasSize.type === CanvasSizeType.DESKTOP);
        setSquare(selectedCanvasSize.type === CanvasSizeType.SQUARE);

        if (canvasRef.current && canvasRef.current !== null) {
            const ctx = canvasRef.current.getContext("2d");
            if (ctx && selectedImageToEdit) {
                const img = new Image();
                img.src = selectedImageToEdit;
                img.onload = () => {
                    handleImageLoad(img, ctx);
                };
            }
        }
    }, [selectedCanvasSize]);

    //#region Add image to canvas
    const updateCanvasSize = (width: number, height: number): Promise<void> => {
        return new Promise((resolve) => {
            const aspectRatio = width / height;
            let closestMatch: ICanvasSizeOption = CanvasSizeOptions[0];
            let smallestDiff = Infinity;

            CanvasSizeOptions.filter((c) => c.type !== CanvasSizeType.NOT_SET).forEach((option) => {
                const optionAspectRatio = option.pxWidth / option.pxHeight;
                const diff = Math.abs(aspectRatio - optionAspectRatio);
                if (diff < smallestDiff) {
                    smallestDiff = diff;
                    closestMatch = option;
                }
            });
            handleSetSelectedCanvasSizeAsync(closestMatch).then((res) => {
                resolve();
            });
        });
    };
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleImageLoad = async (img: HTMLImageElement, ctx: CanvasRenderingContext2D, deleted: boolean = false) => {
        const { width, height } = img;
        if (selectedCanvasSize === CanvasSizeOptions[0] || deleted === true) {
            await updateCanvasSize(width, height);
            handleSeShowLoader(true);
            await delay(3000);
            handleSeShowLoader(false);
        }

        const aspectRatio = width / height;

        const parent = containerRef.current;
        if (parent) {
            const parentWidth = parent.clientWidth;
            const parentHeight = parent.clientHeight;
            let canvasWidth, canvasHeight;
            if (parentWidth / parentHeight > aspectRatio) {
                canvasWidth = parentWidth;
                canvasHeight = parentWidth / aspectRatio;
            } else {
                canvasHeight = parentHeight;
                canvasWidth = parentHeight * aspectRatio;
            }
            canvasRef.current!.width = canvasWidth;
            canvasRef.current!.height = canvasHeight;
            const xOffset = (parentWidth - canvasWidth) / 2;
            const yOffset = (parentHeight - canvasHeight) / 2;
            setCanvasPosition({ x: xOffset, y: yOffset });
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        }
    };
    useEffect(() => {
        // setShowController(true);
        if (canvasRef.current && canvasRef.current !== null) {
            const ctx = canvasRef.current.getContext("2d");
            if (ctx && selectedImageToEdit) {
                const img = new Image();
                img.src = selectedImageToEdit;
                img.onload = () => {
                    handleImageLoad(img, ctx);
                };
                addToHistory(stickerBase64s, textBoxes, imageScale, backgroundImageHistory.transform);
            }
        }
    }, [selectedImageToEdit]);

    //#endregion

    useEffect(() => {
        targetRefStickers.current = targetRefStickers.current.slice(0, stickerBase64s.length);
    }, [stickerBase64s]);

    useEffect(() => {
        targetRefTexts.current = targetRefTexts.current.slice(0, textBoxes.length);
    }, [textBoxes]);

    useEffect(() => {
        if (stickerBase64 !== null) {
            const lastSticker = stickerBase64s.slice(-1)[0];
            const newId = lastSticker ? lastSticker.id + 1 : 1;
            const sticker: ISticker = {
                id: newId,
                base64Sticker: stickerBase64,
                flipX: false,
                flipY: false,
                transform: "translate(0px, 0px) rotate(0) scale(1, 1)",
            };
            setStickerBase64s((prevItems) => {
                const newStickers = [...prevItems, sticker];
                addToHistory(newStickers, textBoxes, imageScale, backgroundImageHistory.transform);
                return newStickers;
            });
        }
        handleSelectStickerToEdit(null);
    }, [stickerBase64]);

    const unselectEverything = () => {
        setSelectedTargetSticker(null);
        setSelectedTargetText(null);
        changeSetActiveTextBoxId("");
        setShowController(false);
        setStickerSelectedId(1000);
    };

    const selectTargetSticker = (index: number, transform: string) => {
        if (!isMovable) {
            const newSticker = targetRefStickers.current[index];
            setSelectedTargetSticker(newSticker);
            setSelectedTargetText(null);

            if (newSticker) {
                newSticker.style.transform = transform;
                helper.createFrame(newSticker, { transform: transform });
            }
        }
    };

    const selectTargetText = (index: number, transform: string) => {
        if (!isMovable) {
            const newText = targetRefTexts.current[index];
            setSelectedTargetText(newText);
            setSelectedTargetSticker(null);

            if (newText) {
                newText.style.transform = transform;
                helper.createFrame(newText, { transform: transform });
            }
        }
    };

    const handleSetStickerIndex = (id: number) => {
        if (!isMovable) {
            changeSetActiveTextBoxId("");
            setStickerSelectedId(id);
        }
    };

    const handleSetEditableDivIndex = async (id: string) => {
        if (!isMovable) {
            setStickerSelectedId(1000);
            changeSetActiveTextBoxId(id);
            await new Promise((resolve) => setTimeout(resolve, 0));
            const index = textBoxes.findIndex((textBox) => textBox.id === id);
            if (index !== -1) {
                const editableDiv = targetRefTexts.current[index];
                if (editableDiv && editableDiv.firstChild) {
                    const contentEditableElement = editableDiv.firstChild as HTMLElement;
                    contentEditableElement.focus();
                }
            }
        }
    };

    const [helper] = React.useState(() => {
        return new MoveableHelper();
    });

    const deleteSticker = () => {
        if (stickerSelectedId !== 1000) {
            setSelectedTargetSticker(null);
            const newStickers = stickerBase64s.filter((s) => s.id !== stickerSelectedId);
            setStickerBase64s(newStickers);
            targetRefStickers.current.splice(stickerSelectedId, 1);
            addToHistory(newStickers, textBoxes, imageScale, backgroundImageHistory.transform);
        } else if (selectedTargetText) {
            const index = targetRefTexts.current.indexOf(selectedTargetText);
            if (index !== -1) {
                const newTextBoxes = textBoxes.filter((_, i) => i !== index);
                deleteText(index);
                setSelectedTargetText(null);
                targetRefTexts.current = targetRefTexts.current.filter((_, i) => i !== index);
                addToHistory(stickerBase64s, newTextBoxes, imageScale, backgroundImageHistory.transform);
            }
        } else if (showController) {
            handleSelectImageToEdit(null, true);

            if (movableDivision && movableDivision.current) {
                const movableDiv = movableDivision.current;
                movableDiv.style.left = `0`;
                movableDiv.style.top = `0`;
            }
            handleSetScale(1);
            updateCanvasSize(selectedCanvasSize.pxWidth, selectedCanvasSize.pxHeight);
            if (canvasRef.current && canvasRef.current !== null) {
                const ctx = canvasRef.current.getContext("2d");

                if (ctx) {
                    // Clear the entire canvas
                    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

                    // Reset any transformations applied to the canvas
                    ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset the transformation matrix to the identity matrix

                    // Optionally reset the canvas state (fillStyle, strokeStyle, etc.) to defaults
                    ctx.fillStyle = "#EBEAED"; // Background color reset
                    ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                }
                const canvas = canvasRef.current;
                const currentTransform = canvas.style.transform;

                let newTransform = "scale(1)";

                canvas.style.transform = "scale(1)";

                setImageScale(1);

                helper.createFrame(canvas, { transform: newTransform });
                unselectEverything();
            }
            unselectEverything();
        }
    };

    //#region Save and download Image
    useEffect(() => {
        setSaveImage(saveImage);
    }, [setSaveImage]);

    const saveImage = async () => {
        handleSeShowLoader(true);
        if (movableDivision && movableDivision.current) {
            const movableDiv = movableDivision.current;
            movableDiv.style.left = `0`;
            movableDiv.style.top = `0`;
            unselectEverything();
        }
        handleSetScale(1);
        setStickerSelectedId(1000);
        changeSetActiveTextBoxId("");

        await delay(1000);
        if (containerRef.current) {
            html2canvas(containerRef.current).then((canvas) => {
                const imageDataUrl = canvas.toDataURL(`image/jpeg`);
                saveResultImage(imageDataUrl);

                const link = document.createElement("a");

                const fileExtension = "jpeg";
                link.download = `edited-image.${fileExtension}`;
                link.href = imageDataUrl;
                link.click();
                handleSeShowLoader(false);
            });
        }
    };
    //#endregion

    // Handle button on control panel
    useImperativeHandle(ref, () => ({
        deleteSticker,
        saveImage,
        handleFlipX: () => {
            const updatedStickers = stickerBase64s.map((s) => (s.id === stickerSelectedId ? { ...s, flipX: !s.flipX } : s));
            setStickerBase64s(updatedStickers);
            addToHistory(updatedStickers, textBoxes, imageScale, backgroundImageHistory.transform);
        },
        handleFlipY: () => {
            const updatedStickers = stickerBase64s.map((s) => (s.id === stickerSelectedId ? { ...s, flipY: !s.flipY } : s));
            setStickerBase64s(updatedStickers);
            addToHistory(updatedStickers, textBoxes, imageScale, backgroundImageHistory.transform);
        },
        handleText: () => {
            const unique_id = uuid();

            const model: TextBoxes = {
                id: unique_id,
                color: "#000",
                align: "LEFT",
                bold: false,
                underline: false,
                italic: false,
                stroke: false,
                strokeColor: "red",
                font: AvailableFonts.MONTSERRAT,
                transform: "translate(0px, 0px) rotate(0) scale(1, 1)",
            };
            addText(model);
            const newTextBoxes = [...textBoxes, model];
            addToHistory(stickerBase64s, newTextBoxes, imageScale, backgroundImageHistory.transform);
        },
        undo,
        redo,
        handleZoomIn: () => {
            unselectEverything();
            const canvas = canvasRef.current;
            if (canvas) {
                const currentTransform = canvas.style.transform;

                const newScale = Math.min(10, imageScale + 0.1);
                let newTransform;
                if (currentTransform.match(/scale\(\d*\.?\d+\)/)) {
                    newTransform = currentTransform.replace(/scale\(\d*\.?\d+\)/, `scale(${newScale})`);
                } else {
                    newTransform = `${currentTransform} scale(${newScale})`;
                }

                canvas.style.transform = newTransform;
                addToHistory(stickerBase64s, textBoxes, newScale, newTransform);
                setBackgroundImageHistory({ scale: newScale, transform: newTransform });
                setImageScale(newScale);

                helper.createFrame(canvas, { transform: newTransform });
            }
        },
        handleZoomOut: () => {
            unselectEverything();
            const canvas = canvasRef.current;
            if (canvas) {
                const currentTransform = canvas.style.transform;

                const newScale = Math.max(0.1, imageScale - 0.1);
                let newTransform;

                if (currentTransform.match(/scale\(\d*\.?\d+\)/)) {
                    newTransform = currentTransform.replace(/scale\(\d*\.?\d+\)/, `scale(${newScale})`);
                } else {
                    newTransform = `${currentTransform} scale(${newScale})`;
                }
                canvas.style.transform = newTransform;
                addToHistory(stickerBase64s, textBoxes, newScale, newTransform);
                setBackgroundImageHistory({ scale: newScale, transform: newTransform });

                setImageScale(newScale);

                helper.createFrame(canvas, { transform: newTransform });
            }
        },
    }));

    //#region Handle history
    const addToHistory = (newStickers: ISticker[], newTextBoxes: TextBoxes[], imageScale: number, imageTransform: string) => {
        const scale: imageHistory = { scale: imageScale, transform: imageTransform };
        const updatedHistory = [...history.slice(0, currentIndex + 1), { stickers: newStickers, textBoxes: newTextBoxes, backgroundImage: scale }];
        setHistory(updatedHistory);
        setCurrentIndex(updatedHistory.length - 1);
    };

    const undo = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => {
                setSelectedTargetSticker(null);
                setSelectedTargetText(null);
                const newIndex = prevIndex - 1;
                setStickerBase64s(history[newIndex].stickers);
                handleSetTextBoxes(history[newIndex].textBoxes);
                setImageScale(history[newIndex].backgroundImage.scale);
                console.log(history[newIndex].backgroundImage.transform);

                setBackgroundImageHistory({ scale: history[newIndex].backgroundImage.scale, transform: history[newIndex].backgroundImage.transform });

                return newIndex;
            });
        }
    };

    const redo = () => {
        if (currentIndex < history.length - 1) {
            setCurrentIndex((prevIndex) => {
                setSelectedTargetSticker(null);
                setSelectedTargetText(null);
                const newIndex = prevIndex + 1;
                setStickerBase64s(history[newIndex].stickers);
                handleSetTextBoxes(history[newIndex].textBoxes);
                setImageScale(history[newIndex].backgroundImage.scale);
                setBackgroundImageHistory({ scale: history[newIndex].backgroundImage.scale, transform: history[newIndex].backgroundImage.transform });

                return newIndex;
            });
        }
    };

    // Handle history for Stickers
    const handleStickerDragEnd = (e: OnDragEnd, stickerId: number) => {
        const updatedStickers = stickerBase64s.map((sticker) => {
            if (sticker.id === stickerId) {
                const newTransform = e.target.style.transform;
                return {
                    ...sticker,
                    transform: newTransform,
                };
            }
            return sticker;
        });
        setStickerBase64s(updatedStickers);
        addToHistory(updatedStickers, textBoxes, imageScale, backgroundImageHistory.transform);
    };

    const handleBackgroundDragEnd = (e: OnDragEnd) => {
        const newTransform = e.target.style.transform;
        setBackgroundImageHistory({ scale: imageScale, transform: newTransform });
        addToHistory(stickerBase64s, textBoxes, imageScale, newTransform);
    };

    // Handle history for Text
    const handleTextDragEnd = (e: OnDragEnd, textId: string) => {
        const updatedTextBoxes = textBoxes.map((textBox) => {
            if (textBox.id === textId) {
                const newTransform = e.target.style.transform;
                return {
                    ...textBox,
                    transform: newTransform,
                };
            }

            return textBox;
        });
        handleSetTextBoxes(updatedTextBoxes);
        addToHistory(stickerBase64s, updatedTextBoxes, imageScale, backgroundImageHistory.transform);
    };

    //#endregion

    //#region  Share on X
    const shareOnX = async () => {
        handleSeShowLoader(true);
        if (movableDivision && movableDivision.current) {
            const movableDiv = movableDivision.current;
            movableDiv.style.left = `0`;
            movableDiv.style.top = `0`;
            unselectEverything();
        }
        handleSetScale(1);
        setStickerSelectedId(1000);
        changeSetActiveTextBoxId("");
        await delay(2000);
        if (containerRef.current) {
            const canvas = await html2canvas(containerRef.current);
            const imageDataUrl = canvas.toDataURL("image/png");
            const base64String = imageDataUrl.split(",")[1];
            localStorage.setItem("finalImage", base64String);

            handleSetFinalImageBase64(base64String);
            handleSeShowLoader(false);
            return true;
        }
        return false;
    };
    useEffect(() => {
        setShareOnX(shareOnX);
    }, [setShareOnX]);
    //#endregion

    // unselect image on resize
    useEffect(() => {
        const handleResize = () => {
            setShowController(false);
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Unselect everything with click outside
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const parentElement = document.getElementById("editableArea");
            const isOutsideStickers = targetRefStickers.current.every((ref) => ref && !ref.contains(event.target as Node));
            const isOutsideTexts = targetRefTexts.current.every((ref) => ref && !ref.contains(event.target as Node));
            const isOutOfBaseImg = canvasRef.current && !canvasRef.current.contains(event.target as Node);
            const isInsideParent = parentElement && parentElement.contains(event.target as Node);

            if (isOutsideStickers && isOutsideTexts && isInsideParent && isOutOfBaseImg) {
                unselectEverything();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [targetRefStickers, targetRefTexts]);

    const deleteButton = {
        name: "deleteButton",

        render(moveable: any) {
            const { renderKey } = moveable.props;
            return (
                <button
                    key={`delete-button-${renderKey}`}
                    onClick={deleteSticker}
                    className="border border-white flex items-center justify-center w-4 h-4 absolute -top-8 -left-8 rounded-[50%] bg-[#44AAFF]"
                >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-white">
                        <path d="M11 1L1 11M1 1L11 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            );
        },
    };

    // to handle dragging the `movable-div`
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isMovable) return;

        if (movableDivision && movableDivision.current) {
            unselectEverything();

            const movableDiv = movableDivision.current;

            // Calculate the initial offset between the mouse position and the div's top-left corner
            const offsetX = e.clientX - movableDiv.offsetLeft;
            const offsetY = e.clientY - movableDiv.offsetTop;

            const handleMouseMove = (event: MouseEvent) => {
                // Calculate the new position of the div
                const newX = event.clientX - offsetX;
                const newY = event.clientY - offsetY;

                // Update the div's position
                movableDiv.style.position = "absolute";
                movableDiv.style.left = `${newX}px`;
                movableDiv.style.top = `${newY}px`;
            };

            const handleMouseUp = () => {
                // Remove the mousemove and mouseup event listeners
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
            };

            // Add the mousemove and mouseup event listeners
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        }
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!isMovable) return;

        if (movableDivision && movableDivision.current) {
            unselectEverything();

            const movableDiv = movableDivision.current;

            // Calculate the initial offset between the touch position and the div's top-left corner
            const offsetX = e.touches[0].clientX - movableDiv.offsetLeft;
            const offsetY = e.touches[0].clientY - movableDiv.offsetTop;

            const handleTouchMove = (event: TouchEvent) => {
                // Calculate the new position of the div
                const newX = event.touches[0].clientX - offsetX;
                const newY = event.touches[0].clientY - offsetY;

                // Update the div's position
                movableDiv.style.position = "absolute";
                movableDiv.style.left = `${newX}px`;
                movableDiv.style.top = `${newY}px`;
            };

            const handleTouchEnd = () => {
                // Remove the touchmove and touchend event listeners
                document.removeEventListener("touchmove", handleTouchMove);
                document.removeEventListener("touchend", handleTouchEnd);
            };

            // Add the touchmove and touchend event listeners
            document.addEventListener("touchmove", handleTouchMove);
            document.addEventListener("touchend", handleTouchEnd);

            // Prevent the default action to avoid scrolling
            e.preventDefault();
        }
    };

    const handleSetShowController = () => {
        if (!isMovable && selectedImageToEdit) {
            setShowController(true);
            const canvas = canvasRef.current;
            if (canvas) {
                const currentTransform = canvas.style.transform;
                helper.createFrame(canvas, { transform: currentTransform });
            }
        }
    };

    useEffect(() => {
        unselectEverything();
        if (scale === 1 && !isMovable && movableDivision && movableDivision.current) {
            const movableDiv = movableDivision.current;
            movableDiv.style.left = `0`;
            movableDiv.style.top = `0`;
        }
    }, [scale, isMovable]);

    return (
        <>
            <div id="editableArea" className={`${className} ${scale > 1 ? "overflow-hidden editable-aria-scroll" : ""} relative flex h-full flex-col items-center justify-center rounded-lg gap-3`}>
                {showLoader && (
                    <div className={`absolute left-0 top-0 bg-[#1a1a1a54] flex w-full h-full items-center justify-center z-[500]`}>
                        <div className="w-40 sm:w-52">
                            <img src="/loading.gif" alt="loading" className="w-full h-full" />
                        </div>
                    </div>
                )}
                <div
                    className={`
                ${
                    x
                        ? "w-[382px] h-[127px] lg:h-[319px] lg:w-[672px] xl:h-[319px] xl:w-[927px] desktop:h-[319px] desktop:w-[956px]"
                        : desktop
                        ? "w-[382px] h-[215px] lg:h-[537px] lg:w-[672px] xl:h-[537px] xl:w-[927px] desktop:h-[537px] desktop:w-[956px]"
                        : mobile
                        ? "w-[382px] h-[680px] lg:h-[550px] lg:w-[309px]"
                        : "w-[382px] h-[382px] lg:w-[550px] lg:h-[550px]"
                }
                relative z-0 flex items-center justify-center`}
                    ref={containerRef}
                >
                    <div
                        ref={movableDivision}
                        className="w-full h-full absolute top-0 left-0 bg-bg_light_third overflow-hidden"
                        style={{
                            transform: `scale(${scale})`,
                            transformOrigin: "center",
                            cursor: isMovable ? "move" : "default",
                        }}
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleTouchStart}
                    >
                        <canvas
                            ref={canvasRef}
                            style={{ position: "absolute", top: canvasPosition.y, left: canvasPosition.x, transform: backgroundImageHistory.transform }}
                            onClick={() => {
                                handleSetShowController();
                                setSelectedTargetSticker(null);
                                setSelectedTargetText(null);
                                changeSetActiveTextBoxId("");
                                setStickerSelectedId(1000);
                            }}
                        />

                        {stickerBase64s.map((stickerBase64, index) => (
                            <div
                                className={`target absolute top-10 left-10 z-40 draggable-img`}
                                key={stickerBase64.id}
                                ref={(el) => (targetRefStickers.current[index] = el)}
                                onClick={() => {
                                    if (!isMovable) {
                                        selectTargetSticker(index, stickerBase64.transform);
                                        setShowController(false);
                                    }
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                style={{ transform: stickerBase64.transform }}
                            >
                                <img
                                    src={stickerBase64.base64Sticker}
                                    onClick={() => {
                                        if (!isMovable) {
                                            handleSetStickerIndex(stickerBase64.id);
                                        }
                                    }}
                                    onMouseDown={(e) => e.preventDefault()}
                                    style={{ transform: `scaleX(${stickerBase64.flipX ? -1 : 1}) scaleY(${stickerBase64.flipY ? -1 : 1})` }}
                                    className="w-40"
                                    alt=""
                                />
                            </div>
                        ))}

                        {textBoxes.map((textBox, index) => (
                            <div
                                className={`target absolute top-10 left-10 z-40 draggable-img`}
                                key={textBox.id}
                                ref={(el) => (targetRefTexts.current[index] = el)}
                                onClick={() => {
                                    handleSetEditableDivIndex(textBox.id);
                                    selectTargetText(index, textBox.transform);
                                    setShowController(false);
                                }}
                                style={{ transform: textBox.transform }}
                            >
                                <div
                                    className={`text-xl  p-2 ${textBox.bold ? "font-black" : ""} ${textBox.italic ? "italic" : ""} ${textBox.underline ? "underline" : ""} ${
                                        textBox.align === "RIGHT" ? "text-right" : textBox.align === "CENTER" ? "text-center" : textBox.align === "right" ? "text-left" : ""
                                    }
                                ${
                                    textBox.font === AvailableFonts.CORMOTANT
                                        ? "font-cormorant"
                                        : textBox.font === AvailableFonts.MONTSERRAT
                                        ? "font-montserrat"
                                        : textBox.font === AvailableFonts.DM_SANS
                                        ? "font-dm_sans"
                                        : textBox.font === AvailableFonts.SPACE_MONO
                                        ? "font-space_mono"
                                        : textBox.font === AvailableFonts.SYNE
                                        ? "font-syne"
                                        : textBox.font === AvailableFonts.WORK_SANS
                                        ? "font-work_sans"
                                        : ""
                                }

                                `}
                                    style={{ color: textBox.color, WebkitTextStroke: textBox.stroke ? `1px ${textBox.strokeColor}` : "0" }}
                                    contentEditable="true"
                                    suppressContentEditableWarning={true}
                                >
                                    Meme Generator
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {showController && (
                <Moveable
                    target={canvasRef}
                    draggable={true}
                    scalable={false}
                    keepRatio={true}
                    rotatable={false}
                    onDragStart={(e) => {
                        helper.onDragStart(e);
                    }}
                    onDrag={(e) => {
                        helper.onDrag(e);
                    }}
                    onDragEnd={(e) => {
                        handleBackgroundDragEnd(e);
                    }}
                    onRotateStart={(e) => {
                        helper.onRotateStart(e);
                    }}
                    onRotate={(e) => {
                        helper.onRotate(e);
                    }}
                    preventRightClick={true}
                    hideDefaultLines={true}
                />
            )}

            {selectedTargetSticker && (
                <Moveable
                    target={selectedTargetSticker}
                    ables={[deleteButton]}
                    deleteButton={true}
                    draggable={true}
                    scalable={true}
                    keepRatio={true}
                    rotatable={true}
                    origin={false}
                    onDragStart={helper.onDragStart}
                    onDrag={helper.onDrag}
                    onDragEnd={(e) => {
                        handleStickerDragEnd(e, stickerSelectedId);
                    }}
                    onScaleStart={helper.onScaleStart}
                    onScale={helper.onScale}
                    onScaleEnd={(e) => {
                        handleStickerDragEnd(e, stickerSelectedId);
                    }}
                    onRotateStart={helper.onRotateStart}
                    onRotate={helper.onRotate}
                    onRotateEnd={(e) => {
                        handleStickerDragEnd(e, stickerSelectedId);
                    }}
                    preventRightClick={true}
                    hideDefaultLines={true}
                />
            )}

            {selectedTargetText && (
                <Moveable
                    ref={moveableTextBoxRef}
                    target={selectedTargetText}
                    ables={[deleteButton]}
                    deleteButton={true}
                    draggable={true}
                    scalable={true}
                    keepRatio={true}
                    rotatable={true}
                    onDragStart={helper.onDragStart}
                    onDrag={helper.onDrag}
                    onDragEnd={(e) => {
                        handleTextDragEnd(e, activeTextBoxId);
                    }}
                    onScaleStart={helper.onScaleStart}
                    onScale={helper.onScale}
                    onScaleEnd={(e) => {
                        handleTextDragEnd(e, activeTextBoxId);
                    }}
                    onRotateStart={helper.onRotateStart}
                    onRotate={helper.onRotate}
                    onRotateEnd={(e) => {
                        handleTextDragEnd(e, activeTextBoxId);
                    }}
                    dragFocusedInput={true}
                    hideDefaultLines={true}
                />
            )}
        </>
    );
});

export default EditImageAria;
