import { useEffect, useRef, useState } from "react";
import Stickers from "./Stickers/Stickers";
import Others from "./Others/Others";
import { useTemplateLibrary } from "../../Context/useTemplateLibrary";
import { useStickersLibrary } from "../../Context/useStickerLibrary";
import Memes from "./Memes/Memes";
import Banners from "./Banners/Banners";
import Wallpapers from "./Wallpapers/Wallpapers";
import Trump from "./Trump/Trump";
import Voids from "./Void/Viods";

type Props = {};

export enum LeftSideSection {
    ALL,
    MEMES,
    WALLPAPERS,
    BANNERS,
    STICKERS,
    OTHERS,
    TRUMP,
    VOID,
}

const LeftSide = () => {
    const [currentPage, setCurrentPage] = useState<LeftSideSection>(LeftSideSection.ALL);
    const { getTemplates } = useTemplateLibrary();
    const { getStickers, getOthers, getTrump, getVoid } = useStickersLibrary();
    const [fade, setFade] = useState(false);

    const handleViewAllClick = (page: LeftSideSection) => {
        switch (page) {
            case LeftSideSection.MEMES:
                getTemplates("memes");
                break;
            case LeftSideSection.WALLPAPERS:
                getTemplates("wallpapers");
                break;
            case LeftSideSection.BANNERS:
                getTemplates("banners");
                break;
            case LeftSideSection.OTHERS:
                getOthers();
                break;
            case LeftSideSection.STICKERS:
                getStickers();
                break;
            case LeftSideSection.TRUMP:
                getTrump();
                break;
            case LeftSideSection.VOID:
                getVoid();
                break;
            default:
                break;
        }

        setFade(true);
        setTimeout(() => {
            setCurrentPage(page);
            setFade(false);
        }, 500); // duration of the fade animation
    };

    const stickersRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number | undefined>(undefined);
    useEffect(() => {
        const updateHeight = () => {
            const adjacentElement = document.getElementById("adjacent-component-id");
            if (adjacentElement && stickersRef.current) {
                // setHeight(adjacentElement.offsetHeight - 62);
                setHeight(window.innerHeight - 150);
            }
        };

        updateHeight();

        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, [currentPage]);

    return (
        <div ref={stickersRef} className={`hidden lg:flex flex-col gap-6 w-full h-full max-w-max bg-bg_light_third dark:bg-bg_dark_third  py-6 px-5  shadow-header_shadow_left_side z-20`}>
            <div
                className={`fade-container   flex flex-col gap-6 ${fade ? "fade-out" : "fade-in"} 
            ${currentPage === LeftSideSection.ALL ? "overflow-auto pr-1 pb-1" : ""}`}
                style={{ height: currentPage === LeftSideSection.ALL ? height || "auto" : "auto" }}
            >
                {(currentPage === LeftSideSection.ALL || currentPage === LeftSideSection.MEMES) && (
                    <Memes show={currentPage === LeftSideSection.MEMES} handleViewAllClick={handleViewAllClick} className={`flex flex-col gap-4`} />
                )}
                {(currentPage === LeftSideSection.ALL || currentPage === LeftSideSection.BANNERS) && (
                    <Banners show={currentPage === LeftSideSection.BANNERS} handleViewAllClick={handleViewAllClick} className={`flex flex-col gap-4`} />
                )}
                {(currentPage === LeftSideSection.ALL || currentPage === LeftSideSection.WALLPAPERS) && (
                    <Wallpapers show={currentPage === LeftSideSection.WALLPAPERS} handleViewAllClick={handleViewAllClick} className={`flex flex-col gap-4`} />
                )}
                {(currentPage === LeftSideSection.ALL || currentPage === LeftSideSection.OTHERS) && (
                    <Others show={currentPage === LeftSideSection.OTHERS} handleViewAllClick={handleViewAllClick} className={`flex flex-col gap-4`} />
                )}
                {(currentPage === LeftSideSection.ALL || currentPage === LeftSideSection.VOID) && (
                    <Voids show={currentPage === LeftSideSection.VOID} handleViewAllClick={handleViewAllClick} className={`flex flex-col gap-4`} />
                )}

                {(currentPage === LeftSideSection.ALL || currentPage === LeftSideSection.TRUMP) && (
                    <Trump show={currentPage === LeftSideSection.TRUMP} handleViewAllClick={handleViewAllClick} className={`flex flex-col gap-4`} />
                )}

                {(currentPage === LeftSideSection.ALL || currentPage === LeftSideSection.STICKERS) && (
                    <Stickers show={currentPage === LeftSideSection.STICKERS} handleViewAllClick={handleViewAllClick} className={`flex flex-col gap-4`} />
                )}
            </div>
        </div>
    );
};

export default LeftSide;
