import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import HomePage from "../Pages/HomePage/HomePage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import ManageLibrary from "../Pages/ManageLibrary/ManageLibrary";
import TwitterCallback from "../Components/Twitter/TwitterCallback";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { path: "", element: <HomePage /> },
            { path: "login", element: <LoginPage /> },
            { path: "twitter-callback", element: <TwitterCallback /> },

            {
                path: "Admin",
                element: (
                    <ProtectedRoute>
                        <ManageLibrary />
                    </ProtectedRoute>
                ),
            },
        ],
    },
]);
