import { useState } from "react";
import CustomDropdown, { DownloadType } from "./DropDown/dropdown";
import { Page } from "./ShareModal";
import { useGlobalActions } from "../../../Context/useGlobalActionsContext";
import { ArrowIcon2, DownloadIcon } from "../../Common/Icons";

type Props = {
    setCurrentPage: (page: Page) => void;
};

const DownloadPage = ({ setCurrentPage }: Props) => {
    const [dropdownValue, setDropdownValue] = useState<DownloadType>(DownloadType.JPG);
    const { saveImage, showLoader } = useGlobalActions();
    return (
        <>
            <div className="flex flex-col gap-8 w-full">
                <div className="flex justify-start items-center gap-3 cursor-pointer" aria-label="header" onClick={() => setCurrentPage(Page.menu)}>
                    <div className="rotate-180">
                        <ArrowIcon2 color="text-black dark:text-white" />
                    </div>
                    <p className="uppercase font-bold text-lg text-black dark:text-white text-center">DOWNLOAD</p>
                </div>
                <div className="flex flex-col gap-6" aria-label="download">
                    <div className="flex flex-col gap-2">
                        <p className="uppercase text-black dark:text-white">file type</p>
                        <CustomDropdown value={dropdownValue} onChange={setDropdownValue} />
                    </div>
                    <button onClick={saveImage} disabled={showLoader} className="flex justify-center gap-2 rounded-lg py-2 px-4 text-white bg-button_primary w-full leading-7">
                        <DownloadIcon color="red" /> DOWNLOAD
                    </button>
                </div>
            </div>
        </>
    );
};
export default DownloadPage;
