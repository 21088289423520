import { ChangeEvent, useRef } from "react";
import { useTemplateLibrary } from "../../Context/useTemplateLibrary";

type Props = {
    className?: string;
};

const ImageManagement = (props: Props) => {
    const { handleUploadImage, deleteImage, deleteUploadedImage, setUploadedImages, templateImages, uploadedImages } = useTemplateLibrary();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            Array.from(files).forEach((file) => handleUploadImage(file));
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleDelete = (id: number) => {
        deleteImage(id);
    };

    const handleDeleteUploaded = (id: number) => {
        deleteUploadedImage(id);
    };

    const handleSetLibraryType = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const newLibraryType = event.target.value.toUpperCase(); // Ensure uppercase
        const updatedImages = uploadedImages.map((image, i) => (i === index ? { ...image, library_type: newLibraryType } : image));
        setUploadedImages(updatedImages);
    };

    return (
        <>
            <div className={`${props.className} h-full w-full flex flex-col`}>
                <div className={` h-full w-full flex flex-col gap-2 justify-between`}>
                    <div className="flex flex-col  gap-2 ">
                        <div className="flex justify-between ">
                            <p className="text-white">Template:</p>

                            <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} style={{ display: "none" }} multiple />
                            <button
                                onClick={handleButtonClick}
                                className="flex items-center justify-center bg-small-button hover:bg-small-button-Hover dark:bg-bg_dark_secondary rounded-lg w-[76px] h-7 text-sm"
                            >
                                UPLOAD
                            </button>
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-5 gap-2 overflow-y-scroll  rounded-lg">
                            {templateImages.map((image) => (
                                <div className="flex flex-col relative " key={image.id}>
                                    <button
                                        className="absolute top-1 right-1 flex items-center justify-center bg-small-button hover:bg-smallButtonHover rounded-lg w-7 h-7"
                                        onClick={() => handleDelete(image.id)}
                                    >
                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"
                                                stroke="red"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                    <img className="w-full rounded-lg object-cover h-[200px]" src={`${image.base64Thumbnail}`} alt="" />
                                    <input type="text" className="rounded-lg" value={image.library_type} disabled />
                                    <div className="text-white">{image.id}</div>
                                </div>
                            ))}
                            {uploadedImages.map((image, index) => (
                                <div className="flex flex-col relative " key={image.preUploadId}>
                                    <button
                                        className="absolute top-1 right-1 flex items-center justify-center bg-small-button hover:bg-smallButtonHover rounded-lg w-7 h-7"
                                        onClick={() => handleDeleteUploaded(image.preUploadId)}
                                    >
                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"
                                                stroke="red"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                    <img className="w-full rounded-lg object-cover h-[200px]" src={`${image.base64Thumbnail}`} alt="" />
                                    <input type="text" className="rounded-lg uppercase" value={image.library_type} onChange={(event) => handleSetLibraryType(event, index)} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageManagement;
