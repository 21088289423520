import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useDarkMode = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const [darkMode, setDarkMode] = useState<boolean>(() => {
        const localTheme = localStorage.getItem("darkMode");
        if (localTheme !== null) {
            return localTheme === "true";
        }
        const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
        return prefersDarkMode.matches;
    });

    useEffect(() => {
        const body = document.body;
        const switchThemeMode = document.getElementById("switchThemeMode");
        if (darkMode) {
            body?.classList.add("dark");
            switchThemeMode?.classList.add("dark");
        } else {
            body?.classList.remove("dark");
            switchThemeMode?.classList.remove("dark");
        }
        localStorage.setItem("darkMode", String(darkMode));
    }, [darkMode]);

    return [darkMode, setDarkMode];
};
