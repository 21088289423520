// import http from "axios";
import { UserProfileToken } from "../Models/User";
import { handleError } from "../Helpers/ErrorHandler";
import { Response } from "../Lib/types";
import http from "../Lib/axiosConfig";


export const loginAPI = async (password: string) => {
  try {
    const data = await http.post<Response<UserProfileToken>>("auth/login", {
      password: password,
    });
    return data;
  } catch (error) {
    handleError(error);
  }
  
};
