import { AvailableFonts } from "../Components/MainArea/ControlPanel/TextEditor/FontsDropDown";

export class TemplateLibraryDto {
    id!: number;
    base64Image!: string;
    base64Thumbnail!: string;
    file?: File;
    unit8ArrayImage?: Uint8Array;
    isPreUploaded: boolean = false;
    preUploadId!: number;
    library_type!: string;
}

export class StickerLibraryDto {
    id!: number;
    base64Sticker!: string;
    file?: File;
    unit8ArraySticker!: Uint8Array;
    isPreUploaded: boolean = false;
    preUploadId!: number;
    library_type!: string;
}

export class TextBoxes {
    id!: string;
    color!: string;
    bold!: boolean;
    italic!: boolean;
    underline!: boolean;
    align!: string;
    stroke!: boolean;
    strokeColor!: string;
    font!: AvailableFonts;
    transform!: string;
}
