type Props = {
    show: boolean;
    setToggleConfirmModal: (isOpen: boolean) => void;
};
const ConfirmModal = ({ setToggleConfirmModal, show = false }: Props) => {
    const refreshPage = () => {
        window.location.href = "/";
    };
    return (
        <>
            <div
                className={`${
                    !show ? "opacity-0 pointer-events-none" : "opacity-100"
                } flex items-center justify-center z-50 fixed top-0 left-0 w-full h-full overflow-auto transition-opacity duration-300 ease-in`}
            >
                <div className="absolute top-0 left-0 w-full h-full  "></div>
                <div className={`fixed flex items-center justify-center py-6 px-10 rounded-2xl bg-bg_light_primary dark:bg-bg_dark_primary max-w-[444px] shadow-confirm_modal`}>
                    <div className="flex flex-col gap-6 w-full">
                        <div className="flex justify-between items-center py-2" aria-label="header">
                            <p className="uppercase font-black text-lg text-text_light_secondary dark:text-white text-center">You’re leaving this page</p>

                            <svg
                                onClick={() => setToggleConfirmModal(false)}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-text_light_secondary dark:text-white"
                            >
                                <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>

                        <p className="text-text_light_primary dark:text-white font-medium uppercase leading-5">
                            progress that you’ve made will not be saved. Are you sure you want to leave this page?
                        </p>

                        <div className="flex gap-3 justify-between w-full min-h-10">
                            <button className="bg-hr_light dark:bg-text_light_secondary  rounded-lg py-2 px-4 text-white text-sm w-full" onClick={() => setToggleConfirmModal(false)}>
                                CANCEL
                            </button>
                            <button className="bg-button_primary rounded-lg py-2 px-4 text-white text-sm w-full " onClick={refreshPage}>
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmModal;
