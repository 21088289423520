import { ChangeEvent, useEffect, useRef, useState } from "react";
import ControlPanel from "./ControlPanel/ControlPanel";
import UploadArea from "./UploadArea/UploadArea";
import EditImageAria, { EditImageAriaHandles } from "./EditImageAria/EditImageAria";
import { useTemplateLibrary } from "../../Context/useTemplateLibrary";

import { useStickersLibrary } from "../../Context/useStickerLibrary";
import ControlPanelMobile from "./ControlPanel/ControlPanelMobile";
import { useCanvasSize } from "../../Context/useCanvasSize";
import { CanvasSizeType } from "./ControlPanel/CanvasSize/CanvasSizeData";
import { TemplateLibraryDto } from "../../Models/Library";
import Upload from "./UploadArea/Upload.svg";

type Props = {};

const MainArea = () => {
    const { selectedCanvasSize } = useCanvasSize();
    const { handleSelectImageToEdit, isEditImageAriaPage } = useTemplateLibrary();
    const { selectedImageToEdit } = useTemplateLibrary();
    const { selectedStickerToEdit, handleSelectStickerToEdit } = useStickersLibrary();
    const editImageRef = useRef<EditImageAriaHandles | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFlipX = () => {
        editImageRef.current?.handleFlipX();
    };

    const handleFlipY = () => {
        editImageRef.current?.handleFlipY();
    };

    const handleText = () => {
        editImageRef.current?.handleText();
    };

    const undo = () => {
        editImageRef.current?.undo();
    };

    const redo = () => {
        editImageRef.current?.redo();
    };

    const zoomIn = () => {
        editImageRef.current?.handleZoomIn();
    };

    const zoomOut = () => {
        editImageRef.current?.handleZoomOut();
    };

    const handleDeleteClick = () => {
        handleSelectStickerToEdit(null);
        editImageRef.current?.deleteSticker();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            let model: TemplateLibraryDto = {
                base64Image: URL.createObjectURL(file),
                base64Thumbnail: URL.createObjectURL(file),
                id: 0,
                file: undefined,
                unit8ArrayImage: undefined,
                isPreUploaded: false,
                preUploadId: 0,
                library_type: "",
            };
            handleSelectImageToEdit(model, true);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className={`flex flex-col h-auto min-h-full gap-5 py-5 pt-0 xl:pb-5 px-0 sm:px-6 w-full`} id="adjacent-component-id">
            <ControlPanel handleFlipY={handleFlipY} handleFlipX={handleFlipX} handleText={handleText} handleDeleteClick={handleDeleteClick} undo={undo} redo={redo} zoomIn={zoomIn} zoomOut={zoomOut} />

            <ControlPanelMobile
                className=""
                handleFlipY={handleFlipY}
                handleFlipX={handleFlipX}
                handleText={handleText}
                handleDeleteClick={handleDeleteClick}
                undo={undo}
                redo={redo}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
            />

            {!isEditImageAriaPage ? (
                <div className="h-full w-full">
                    <UploadArea className="flex justify-center h-full items-center w-full " />
                    <div className={`${selectedCanvasSize.type === CanvasSizeType.TWITTER ? "block lg:hidden" : "hidden"} flex flex-col gap-2 items-center`}>
                        <p className={`font-black text-2xl w-max lg:w-auto text-center uppercase tracking-[2.4px] dark:text-white`}>
                            Choose a template <br /> <span className="font-medium italic">or upload yours</span>
                            <br /> to start
                        </p>
                        <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} style={{ display: "none" }} />
                        <button onClick={handleUploadClick} className={`flex justify-center gap-2 w-max lg:w-auto py-2 px-4 rounded-lg bg-button_primary text-white`}>
                            <img src={Upload} alt="" /> Upload an image
                        </button>
                    </div>
                </div>
            ) : (
                <EditImageAria ref={editImageRef} className="" stickerBase64={selectedStickerToEdit} />
            )}
        </div>
    );
};

export default MainArea;
