import React, { useState } from "react";

import { ArrowIcon, ImgIcon, PdfIcon } from "../../../Common/Icons";
import { useGlobalActions } from "../../../../Context/useGlobalActionsContext";

export class DownloadTypeOption {
    type!: DownloadType;
    icon!: JSX.Element;
}

export enum DownloadType {
    PNG = "PNG",
    JPG = "JPG",
    PDF = "PDF",
}

interface CustomDropdownProps {
    value: DownloadType;
    onChange: (value: DownloadType) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<JSX.Element>(<ImgIcon color="text-black dark:text-white" />);
    const { handleSetDownloadType } = useGlobalActions();
    const downloadTypeOptions: DownloadTypeOption[] = [
        {
            type: DownloadType.PNG,
            icon: <ImgIcon color="text-black dark:text-white" />,
        },
        { type: DownloadType.JPG, icon: <ImgIcon color="text-black dark:text-white" /> },
        { type: DownloadType.PDF, icon: <PdfIcon color="text-black dark:text-white" /> },
    ];

    const handleOptionClick = (option: DownloadTypeOption) => {
        onChange(option.type);
        setSelectedIcon(option.icon);
        setIsOpen(false);

        handleSetDownloadType(option.type.toLowerCase());
    };

    return (
        <div className="relative">
            <button
                type="button"
                className="font-sen text-base text-black leading-4 bg-white dark:bg-bg_dark_secondary border border-modal_light_border dark:border-bg_dark_secondary  rounded-lg px-4 py-2 w-full"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex w-full  text-black dark:text-white justify-between items-center">
                    <div className="flex justify-center items-center gap-2">
                        {selectedIcon} {value}
                    </div>
                    <ArrowIcon color={`ml-2 transition-transform transform ${isOpen ? "rotate-180" : "rotate-0"} text-black dark:text-white`} />
                </div>
            </button>
            {isOpen && (
                <ul className="flex flex-col gap-5 absolute z-10 bg-white dark:bg-bg_dark_secondary border border-modal_light_border dark:border-bg_dark_secondary  p-4 rounded-lg mt-2 w-full">
                    {downloadTypeOptions.map((option, index) => (
                        <li key={index} className="flex justify-between cursor-pointer" onClick={() => handleOptionClick(option)}>
                            <div className="flex gap-2 dark:text-white">
                                {option.icon} {option.type}
                            </div>
                            {value === option.type && (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                    <path d="M20 6L9 17L4 12" stroke="#F67D52" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
