import axios from "axios";

const http = axios.create({
    baseURL: "https://memebox-app-bxjiq.ondigitalocean.app/api",
    // baseURL: "http://localhost:3003/api",
});

export const http2 = axios.create({
    baseURL: "https://memebox-app-bxjiq.ondigitalocean.app/api",
    // baseURL: "http://localhost:3003/api",
});

export const setAuthToken = (token: string | null) => {
    if (token) {
        http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete http.defaults.headers.common["Authorization"];
    }
};

export default http;
