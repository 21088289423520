import { useEffect, useRef, useState } from "react";
import DownloadPage from "./DownloadPage";
import ShareOnSocial from "./ShareOnSocial";
import { ArrowIcon2, DownloadIcon, ShareIcon } from "../../Common/Icons";
import { useGlobalActions } from "../../../Context/useGlobalActionsContext";

export enum Page {
    menu,
    download,
    share,
}

type Props = {
    toggleShareModal: boolean;
    setToggleShareModal: (show: boolean) => void;
    buttonRef: React.RefObject<HTMLButtonElement>;
};

const ShareModal = ({ toggleShareModal, setToggleShareModal, buttonRef }: Props) => {
    const [currentPage, setCurrentPage] = useState<Page>(Page.share);
    const modalRef = useRef<HTMLDivElement>(null);
    const { saveImage } = useGlobalActions();
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node) && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                setToggleShareModal(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={modalRef}
            className={`${!toggleShareModal ? "opacity-0 pointer-events-none" : "opacity-100 z-[500]"} ${
                currentPage === Page.menu ? "h-[284px] lg:h-auto" : "h-[280px] lg:h-auto"
            } fixed lg:absolute bottom-0 lg:bottom-auto lg:top-14 right-0 w-full lg:w-max z-[100] px-5 py-6 rounded-t-3xl lg:rounded-x bg-bg_light_primary dark:bg-bg_dark_third border 
            border-modal_light_border dark:border-bg_dark_secondary  lg:transition-opacity lg:duration-300 lg:ease-in`}
        >
            <div className="relative w-full lg:w-64">
                {currentPage === Page.menu && (
                    <div className={`flex ${currentPage === Page.menu ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
                        <div className="flex flex-col gap-8 w-full">
                            <div className="flex lg:justify-center justify-between">
                                <p className="uppercase font-bold text-lg text-black dark:text-white text-center">SHARE THIS MEME</p>
                                <svg
                                    onClick={() => setToggleShareModal(false)}
                                    width="11"
                                    height="12"
                                    viewBox="0 0 11 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="lg:hidden text-text_light_secondary dark:text-white"
                                >
                                    <path d="M11 1L1 11M1 1L11 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="flex flex-col gap-6 lg:gap-4 min-w-64">
                                <div className="flex justify-between cursor-pointer" onClick={saveImage}>
                                    <div className="flex gap-3 text-base font-medium text-black dark:text-white">
                                        <DownloadIcon color="" /> DOWNLOAD
                                    </div>
                                    {/* <ArrowIcon2 color="text-black dark:text-white" /> */}
                                </div>

                                <div className="flex justify-between cursor-pointer" onClick={() => setCurrentPage(Page.share)}>
                                    <div className="flex gap-3 text-base font-medium text-black dark:text-white">
                                        <ShareIcon color="red" />
                                        SHARE ON X
                                    </div>
                                    <ArrowIcon2 color="text-black dark:text-white" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {currentPage === Page.download && (
                    <div className={`flex ${currentPage === Page.download ? "opacity-100" : "opacity-0 pointer-events-none"} `}>
                        <DownloadPage setCurrentPage={setCurrentPage} />
                    </div>
                )}

                {currentPage === Page.share && (
                    <div className={`flex ${currentPage === Page.share ? "opacity-100" : "opacity-0 pointer-events-none"} `}>
                        <ShareOnSocial setCurrentPage={setCurrentPage} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShareModal;
