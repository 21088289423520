import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { UserProvider } from "./Context/useAuth";
import { Outlet } from "react-router";
import Header from "./Components/Header/Header";
import { ImagesLibraryProvider } from "./Context/useTemplateLibrary";
import { StickersLibraryProvider } from "./Context/useStickerLibrary";
import { TextsLibraryProvider } from "./Context/useTextLibrary";
import { CanvasSizeProvider } from "./Context/useCanvasSize";
import { GlobalActionsProvider } from "./Context/useGlobalActionsContext";
import { useEffect, useState } from "react";

function App() {
    return (
        <>
            <div className="flex flex-col min-h-screen h-screen">
                <UserProvider>
                    <GlobalActionsProvider>
                        <CanvasSizeProvider>
                            <TextsLibraryProvider>
                                <ImagesLibraryProvider>
                                    <StickersLibraryProvider>
                                        <Header />
                                        <div className="flex-1  ">
                                            <Outlet />
                                        </div>

                                        <ToastContainer />
                                    </StickersLibraryProvider>
                                </ImagesLibraryProvider>
                            </TextsLibraryProvider>
                        </CanvasSizeProvider>
                    </GlobalActionsProvider>
                </UserProvider>
            </div>
        </>
    );
}

export default App;
