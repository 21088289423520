import { ChangeEvent, useState } from "react";
import { useAuth } from "../../Context/useAuth";

type Props = {};

const LoginPage = (props: Props) => {
    const auth = useAuth();
    const [password, setPassword] = useState<string>("");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = () => {
        auth.loginUser(password);
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center mt-16 gap-6">
                <p className="text-xl text-[#333C53] dark:text-bg_light_primary">PASSWORD:</p>
                <div className="flex gap-3 h-10">
                    <input type="password" value={password} onChange={handleChange} placeholder="Enter password" className="border-solid border-2 border-[#BFBFBF] rounded-lg p-1 px-4 max-w-[184px]" />
                    <button onClick={handleSubmit} className="h-10 text-bg_light_primary text-base p-2 px-3 bg-button_primary hover:bg-buttonOrangeHover rounded-lg min-w-[88px]">
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
