import { ChangeEvent, useRef } from "react";
import Delete from "./Delete.svg";
import { useStickersLibrary } from "../../Context/useStickerLibrary";
type Props = {
    className?: string;
};

const StickerManagement = (props: Props) => {
    const stickersLibrary = useStickersLibrary();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            Array.from(files).forEach((file) => stickersLibrary.handleUploadSticker(file));
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleDelete = (id: number) => {
        stickersLibrary.deleteSticker(id);
    };

    const handleDeleteUploaded = (id: number) => {
        stickersLibrary.deleteUploadedSticker(id);
    };

    return (
        <>
            <div className={`${props.className} flex flex-col flex-shrink-0 gap-2 w-full  min-h-[100px]`}>
                <div className="flex justify-between">
                    <p className="text-white">MANAGE STICKERS:</p>
                    <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} style={{ display: "none" }} multiple />
                    <button onClick={handleButtonClick} className="flex items-center justify-center bg-small-button hover:bg-smallButtonHover rounded-lg w-[76px] h-7 text-sm">
                        UPLOAD
                    </button>
                </div>

                <div className="flex gap-2 overflow-auto min-h-[100px] p-0.5">
                    {stickersLibrary.libraryStickers
                        .filter((s) => s.library_type === "STICKER")
                        .map((s, index) => (
                            <div key={index} className="flex items-center justify-center h-[100px] bg-bg_light_primary rounded-lg">
                                <div className="h-[100px] w-[116px] flex items-center relative justify-center">
                                    <button
                                        className="absolute top-1 right-1 flex items-center justify-center bg-small-button hover:bg-smallButtonHover rounded-lg w-7 h-7"
                                        onClick={() => handleDelete(s.id)}
                                    >
                                        <img src={Delete} alt="" />
                                    </button>
                                    <img src={s.base64Sticker} alt="Sticker" className="max-h-[100px]" />
                                </div>
                                <div>{s.id}</div>
                            </div>
                        ))}
                    {stickersLibrary.uploadedStickers.map((s, index) => (
                        <div key={index} className="flex items-center justify-center h-[100px] bg-bg_light_primary rounded-lg">
                            <div className="h-[100px] w-[116px] flex items-center relative justify-center">
                                <button
                                    className="absolute top-1 right-1 flex items-center justify-center bg-small-button hover:bg-smallButtonHover rounded-lg w-7 h-7"
                                    onClick={() => handleDeleteUploaded(s.preUploadId)}
                                >
                                    <img src={Delete} alt="" />
                                </button>
                                <img src={s.base64Sticker} alt="Sticker" className="max-h-[100px]" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default StickerManagement;
