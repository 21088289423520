import { Response } from "./types";
import { TemplateLibraryDto } from "../Models/Library";
import http from "./axiosConfig";

export const findAll = async (action: string) => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; thumbnail: string }[]>>(`template-library/${action}`);
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const findSix = async (action: string) => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; thumbnail: string }[]>>(`template-library/${action}`);
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const getTemplate = async (id: number) => {
    try {
        const response = await http.get(`template-library/${id}`, {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

// export const getThumbnailTemplate = async (id: number) => {
//     try {
//         const response = await http.get(`template-library/findThumbnail/${id}`, {
//             responseType: "arraybuffer",
//         });
//         return response.data;
//     } catch (error) {
//         console.log("unexpected error: ", error);
//     }
// };

export const getThumbnailTemplate = async (id: number) => {
    try {
        const response = await http.get<Response<{ thumbnail: string }>>(`template-library/findThumbnail/${id}`);
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const addImageLibrary = async (file: File, library_type: string): Promise<Response<TemplateLibraryDto>> => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("library_type", library_type);

        const response = await http.post<Response>("template-library", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Unexpected error: ", error);
        throw error;
    }
};

export const deleteImageLibrary = async (id: number) => {
    try {
        const response = await http.delete<Response>("template-library/" + id);

        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};
