import { Response } from "./types";
import { TemplateLibraryDto } from "../Models/Library";
import http from "./axiosConfig";

export const listSixVoid = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/home-page-void");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const listVoid = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/void");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const addVoidLibrary = async (file: File) => {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await http.post<Response>("sticker-library/create-void-library", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const listSixTrump = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/home-page-trump");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const listTrump = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/trump");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const addTrumpLibrary = async (file: File) => {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await http.post<Response>("sticker-library/create-trump-library", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const listSixStickers = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/home-page-stickers");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const listStickers = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/stickers");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const getSticker = async (id: number) => {
    try {
        const response = await http.get(`sticker-library/${id}`, {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const addStickerLibrary = async (file: File) => {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await http.post<Response>("sticker-library", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const listSixOthers = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/home-page-others");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const listOthers = async () => {
    try {
        const response = await http.get<Response<{ id: number; mime_type: string; library_type: string; sticker: string }[]>>("sticker-library/others");
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const addOtherLibrary = async (file: File) => {
    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await http.post<Response>("sticker-library/create-other-library", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};

export const deleteStickerLibrary = async (id: number) => {
    try {
        const response = await http.delete<Response>("sticker-library/" + id);
        return response.data;
    } catch (error) {
        console.log("unexpected error: ", error);
    }
};
