export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            let base64 = reader.result as string;
            base64 = base64.replace(/^data:.+;base64,/, "data:image/png;base64,");
            resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

export const arrayToBase64 = async (binary: Uint8Array): Promise<string> => {
    const blob = new Blob([binary], { type: "image/png" });
    const base64 = await fileToBase64(blob as File);
    return base64;
};
