import { FadeLoader } from "react-spinners";
import { useStickersLibrary } from "../../../Context/useStickerLibrary";
import { StickerLibraryDto } from "../../../Models/Library";
import { LeftSideSection } from "../LeftSide";
import { useRef, useState, useEffect } from "react";
type Props = {
    className: string;
    show: boolean;
    handleViewAllClick: (page: LeftSideSection) => void;
};
const Stickers = ({ className, show, handleViewAllClick }: Props) => {
    const { libraryStickers, handleSelectStickerToEdit } = useStickersLibrary();

    const handleSelectSticker = (sticker: StickerLibraryDto) => {
        handleSelectStickerToEdit(sticker);
    };
    const stickersRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number | undefined>(undefined);
    useEffect(() => {
        const updateHeight = () => {
            const adjacentElement = document.getElementById("adjacent-component-id");
            if (adjacentElement && stickersRef.current) {
                // setHeight(adjacentElement.offsetHeight - 62);
                setHeight(window.innerHeight - 150);
            }
        };

        updateHeight();

        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, [libraryStickers, show]);
    return (
        <div className={`${className}`} ref={stickersRef} style={{ height: show === true ? height || "auto" : "auto" }}>
            <div className="flex justify-between cursor-pointer" onClick={() => handleViewAllClick(show ? LeftSideSection.ALL : LeftSideSection.STICKERS)}>
                <div className="flex items-center gap-2">
                    {show && (
                        <div className="rotate-180 flex items-center">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-text_light_primary dark:text-text_dark_primary">
                                <path d="M1 8H15M15 8L8 1M15 8L8 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    )}
                    <p className="uppercase font-medium text-black dark:text-white">Billy cat</p>
                </div>
                {!show && (
                    <div className="flex items-center gap-2 ">
                        <p className="uppercase text-sm font-medium text-text_light_primary dark:text-text_dark_primary">View all</p>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-text_light_primary dark:text-text_dark_primary">
                            <path d="M1 8H15M15 8L8 1M15 8L8 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                )}
            </div>
            {libraryStickers && libraryStickers.length > 0 ? (
                <div className={`${show ? "overflow-y-auto overflow-x-hidden pr-1 pb-3" : ""}`} style={{ height: show === true ? height || "auto" : "auto" }}>
                    <div className={`grid ${!show ? `grid-cols-2 desktop:grid-cols-3 grid-rows-2` : `grid-cols-2 grid-rows-2`} gap-4 max-w-[396px]`}>
                        {show
                            ? libraryStickers
                                  .filter((s) => s.library_type === "STICKER")
                                  .map((sticker) => (
                                      <div
                                          onClick={() => handleSelectSticker(sticker)}
                                          key={sticker.id}
                                          className="bg-bg_Light_secondary dark:bg-bg_dark_secondary flex justify-center items-center rounded-lg h-[123px] desktop:w-[186px] max-w-[186px] cursor-pointer"
                                      >
                                          <img src={sticker.base64Sticker} className="h-full w-full object-contain rounded-lg" alt="" />
                                      </div>
                                  ))
                            : libraryStickers
                                  .filter((s) => s.library_type === "STICKER")
                                  .slice(0, 6)
                                  .map((sticker) => (
                                      <div
                                          onClick={() => handleSelectSticker(sticker)}
                                          key={sticker.id}
                                          className="bg-bg_Light_secondary dark:bg-bg_dark_secondary flex justify-center items-center rounded-lg h-[68px] w-[124px]"
                                      >
                                          <img src={sticker.base64Sticker} className="h-full w-full object-contain rounded-lg" alt="" />
                                      </div>
                                  ))}
                    </div>
                </div>
            ) : (
                <div className="flex w-full items-center justify-center min-w-[396px]">
                    <FadeLoader color="#F67D52" />
                </div>
            )}
        </div>
    );
};

export default Stickers;
