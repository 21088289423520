import { useState } from "react";
import { useGlobalActions } from "../../../Context/useGlobalActionsContext";
import { http2 } from "../../../Lib/axiosConfig";

type Props = {};
const ShareToX = () => {
    const { shareOnX, showLoader } = useGlobalActions();
    const [message, setMessage] = useState<string>("");
    const [characterCount, setCharacterCount] = useState<number>(0);
    const handleShareOnX = async () => {
        try {
            const success = await shareOnX();
            if (success) {
                localStorage.setItem("finalMessage", message);

                const response = await http2.get("auth/twitter/request-token");
                const oauthToken = response.data.data.oauthToken;
                if (oauthToken) {
                    // window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}`;
                    const width = 600;
                    const height = 400;
                    const left = window.screen.width / 2 - width / 2;
                    const top = window.screen.height / 2 - height / 2;
                    setMessage("");

                    window.open(`https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}`, "_blank", `width=${width},height=${height},top=${top},left=${left}`);
                } else {
                    console.error("Failed to obtain OAuth token");
                }
            }
        } catch (error) {
            console.error("Error requesting token:", error);
        }
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        if (newValue.length <= 280) {
            setMessage(newValue);
            setCharacterCount(newValue.length);
        }
    };

    return (
        <>
            <div className="w-full ">
                <div className="relative w-full">
                    <textarea className="w-full resize-none rounded-lg" placeholder="What's happening?" maxLength={280} value={message} onChange={handleMessageChange} rows={4} cols={50} />
                    <div className="absolute bottom-2 right-2 text-sm text-gray-500">{characterCount}/280</div>
                </div>
                <div className="flex w-full justify-center items-center gap-2">
                    <button className={`dark:text-white rounded-lg py-2 px-4 text-white bg-button_primary w-full leading-7 uppercase `} disabled={showLoader} onClick={handleShareOnX}>
                        Publish
                    </button>
                </div>
            </div>
        </>
    );
};

export default ShareToX;
