import { useEffect, useState } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

type Props = {
    setStrokeColor: (color: string) => void;
    colorDefault: string | undefined;
};

const ColorPickerStroke = ({ setStrokeColor, colorDefault = "#000" }: Props) => {
    const [def, setDef] = useState("#000");
    const [color, setColor] = useColor(colorDefault);

    useEffect(() => {
        setStrokeColor(color.hex);
    }, [color.hex]);

    return (
        <div className="w-64">
            <ColorPicker color={color} onChange={setColor} hideInput={true} hideAlpha={false} />
        </div>
    );
};

export default ColorPickerStroke;
