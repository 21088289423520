import { createContext, useState } from "react";
import React from "react";
import { CanvasSizeOptions, ICanvasSizeOption } from "../Components/MainArea/ControlPanel/CanvasSize/CanvasSizeData";

type CanvasSizeContextType = {
    handleSetSelectedCanvasSize: (canvasSize: ICanvasSizeOption) => void;
    handleSetSelectedCanvasSizeAsync: (canvasSize: ICanvasSizeOption) => Promise<boolean>;
    selectedCanvasSize: ICanvasSizeOption;
};

type Props = { children: React.ReactNode };

const CanvasSizeContext = createContext<CanvasSizeContextType>({} as CanvasSizeContextType);

export const CanvasSizeProvider = ({ children }: Props) => {
    const [selectedCanvasSize, setSelectedCanvasSize] = useState<ICanvasSizeOption>(CanvasSizeOptions[0]);

    const handleSetSelectedCanvasSize = (canvasSize: ICanvasSizeOption) => {
        setSelectedCanvasSize(canvasSize);
    };

    const handleSetSelectedCanvasSizeAsync = async (canvasSize: ICanvasSizeOption) => {
        setSelectedCanvasSize(canvasSize);
        return true;
    };

    return (
        <CanvasSizeContext.Provider
            value={{
                handleSetSelectedCanvasSize,
                handleSetSelectedCanvasSizeAsync,
                selectedCanvasSize,
            }}
        >
            {children}
        </CanvasSizeContext.Provider>
    );
};

export const useCanvasSize = () => React.useContext(CanvasSizeContext);
