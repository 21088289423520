import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import React from "react";
import { StickerLibraryDto } from "../Models/Library";
import {
    addOtherLibrary,
    addStickerLibrary,
    addTrumpLibrary,
    addVoidLibrary,
    deleteStickerLibrary,
    getSticker,
    listOthers,
    listSixOthers,
    listSixStickers,
    listSixTrump,
    listSixVoid,
    listStickers,
    listTrump,
    listVoid,
} from "../Lib/apiSticker";
import { fileToBase64 } from "../plugins/utils";
import { useGlobalActions } from "./useGlobalActionsContext";

type StickerLibraryContextType = {
    libraryStickers: StickerLibraryDto[];
    uploadedStickers: StickerLibraryDto[];
    uploadedOthers: StickerLibraryDto[];
    uploadedTrump: StickerLibraryDto[];
    uploadedVoid: StickerLibraryDto[];
    selectedStickerToEdit: string | null;
    clearUploadedOthers: () => void;
    getTrump: () => void;
    getSixTrump: () => void;
    addTrump: () => void;
    deleteUploadedTrump: (id: number) => void;
    clearUploadedTrump: () => void;
    handleUploadTrump: (file: File) => void;
    getVoid: () => void;
    getSixVoid: () => void;
    addVoid: () => void;
    deleteUploadedVoid: (id: number) => void;
    clearUploadedVoid: () => void;
    handleUploadVoid: (file: File) => void;
    getStickers: () => void;
    getSixStickers: () => void;
    addStickers: () => void;
    deleteSticker: (id: number) => void;
    deleteUploadedSticker: (id: number) => void;
    clearUploadedSticker: () => void;
    handleUploadSticker: (file: File) => void;
    addOthers: () => void;
    getOthers: () => void;
    getSixOthers: () => void;
    deleteUploadedOther: (id: number) => void;
    handleUploadOther: (file: File) => void;
    handleSelectStickerToEdit: (sticker: StickerLibraryDto | null) => void;
};

type Props = { children: React.ReactNode };

const StickerLibraryContext = createContext<StickerLibraryContextType>({} as StickerLibraryContextType);

export const StickersLibraryProvider = ({ children }: Props) => {
    const { showLoader } = useGlobalActions();

    const [libraryStickers, setLibraryStickers] = useState<StickerLibraryDto[]>([]);

    const [uploadedStickers, setUploadedStickers] = useState<StickerLibraryDto[]>([]);

    const [uploadedOthers, setUploadedOthers] = useState<StickerLibraryDto[]>([]);

    const [uploadedTrump, setUploadedTrump] = useState<StickerLibraryDto[]>([]);
    const [uploadedVoid, setUploadedVoid] = useState<StickerLibraryDto[]>([]);

    const [selectedStickerToEdit, setSelectedStickerToEdit] = useState<string | null>(null);

    useEffect(() => {
        getSixTrump();
        getSixStickers();
        getSixOthers();
        getSixVoid();
    }, []);

    const getSixStickers = async () => {
        try {
            const res = await listSixStickers();
            if (res) {
                const stickers = res.data;

                let list: StickerLibraryDto[] = [];
                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    list.push(dto);
                }
                setLibraryStickers((prevList) => {
                    const idMap = new Map();
                    prevList.forEach((item) => idMap.set(item.id, item));
                    list.forEach((item) => {
                        if (!idMap.has(item.id)) {
                            idMap.set(item.id, item);
                        }
                    });
                    return Array.from(idMap.values());
                });
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const getStickers = async () => {
        try {
            const res = await listStickers();
            if (res) {
                const stickers = res.data;

                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    setLibraryStickers((prevList) => {
                        const idMap = new Map();
                        prevList.forEach((existingItem) => idMap.set(existingItem.id, existingItem));
                        if (!idMap.has(dto.id)) {
                            idMap.set(dto.id, dto);
                        }
                        return Array.from(idMap.values());
                    });
                }
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const getSixTrump = async () => {
        try {
            const res = await listSixTrump();
            if (res) {
                const stickers = res.data;

                let list: StickerLibraryDto[] = [];
                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    list.push(dto);
                }
                setLibraryStickers((prevList) => {
                    const idMap = new Map();
                    prevList.forEach((item) => idMap.set(item.id, item));
                    list.forEach((item) => {
                        if (!idMap.has(item.id)) {
                            idMap.set(item.id, item);
                        }
                    });
                    return Array.from(idMap.values());
                });
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const getTrump = async () => {
        try {
            const res = await listTrump();
            if (res) {
                const stickers = res.data;

                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    setLibraryStickers((prevList) => {
                        const idMap = new Map();
                        prevList.forEach((existingItem) => idMap.set(existingItem.id, existingItem));
                        if (!idMap.has(dto.id)) {
                            idMap.set(dto.id, dto);
                        }
                        return Array.from(idMap.values());
                    });
                }
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const addTrump = () => {
        uploadedTrump.forEach((item) => {
            if (item.file) {
                addTrumpLibrary(item.file)
                    .then(async (res) => {
                        if (res) {
                            res.data.base64Image = item.base64Sticker;
                            setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
                            setLibraryStickers((prevFiles) => [...prevFiles, res.data]);
                            toast.success("Request success");
                        }
                    })
                    .catch((e) => {
                        toast.warning("Server error occurred");
                    });
            } else {
                setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
            }
        });
    };

    const deleteUploadedTrump = (id: number) => {
        setUploadedTrump((prevFiles) => prevFiles.filter((file) => file.preUploadId !== id));
        if (id) {
            toast.success("Delete Success.");
        }
    };

    const handleUploadTrump = async (file: File) => {
        const maxId = Math.max(...uploadedTrump.map((sticker) => sticker.preUploadId ?? 0));

        let model = new StickerLibraryDto();
        model.preUploadId = maxId > 0 ? maxId + 1 : 1;
        model.file = file;
        model.isPreUploaded = true;

        model.base64Sticker = await fileToBase64(file);

        setUploadedTrump((prevFiles) => [...prevFiles, model]);
    };

    const clearUploadedTrump = () => {
        setUploadedTrump([]);
    };

    const getSixVoid = async () => {
        try {
            const res = await listSixVoid();
            if (res) {
                const stickers = res.data;

                let list: StickerLibraryDto[] = [];
                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    list.push(dto);
                }
                setLibraryStickers((prevList) => {
                    const idMap = new Map();
                    prevList.forEach((item) => idMap.set(item.id, item));
                    list.forEach((item) => {
                        if (!idMap.has(item.id)) {
                            idMap.set(item.id, item);
                        }
                    });
                    return Array.from(idMap.values());
                });
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const getVoid = async () => {
        try {
            const res = await listVoid();
            if (res) {
                const stickers = res.data;

                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    setLibraryStickers((prevList) => {
                        const idMap = new Map();
                        prevList.forEach((existingItem) => idMap.set(existingItem.id, existingItem));
                        if (!idMap.has(dto.id)) {
                            idMap.set(dto.id, dto);
                        }
                        return Array.from(idMap.values());
                    });
                }
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const addVoid = () => {
        uploadedVoid.forEach((item) => {
            if (item.file) {
                addVoidLibrary(item.file)
                    .then(async (res) => {
                        if (res) {
                            res.data.base64Image = item.base64Sticker;
                            setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
                            setLibraryStickers((prevFiles) => [...prevFiles, res.data]);
                            toast.success("Request success");
                        }
                    })
                    .catch((e) => {
                        toast.warning("Server error occurred");
                    });
            } else {
                setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
            }
        });
    };

    const getSixOthers = async () => {
        try {
            const res = await listSixOthers();
            if (res) {
                const stickers = res.data;

                let list: StickerLibraryDto[] = [];
                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    setLibraryStickers((prevList) => {
                        const idMap = new Map();
                        prevList.forEach((existingItem) => idMap.set(existingItem.id, existingItem));
                        if (!idMap.has(dto.id)) {
                            idMap.set(dto.id, dto);
                        }
                        return Array.from(idMap.values());
                    });
                }
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const getOthers = async () => {
        try {
            const res = await listOthers();
            if (res) {
                const stickers = res.data;

                let list: StickerLibraryDto[] = [];
                for (const item of stickers) {
                    let dto: StickerLibraryDto = new StickerLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Sticker = item.sticker;

                    setLibraryStickers((prevList) => {
                        const idMap = new Map();
                        prevList.forEach((existingItem) => idMap.set(existingItem.id, existingItem));
                        if (!idMap.has(dto.id)) {
                            idMap.set(dto.id, dto);
                        }
                        return Array.from(idMap.values());
                    });
                }
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const addStickers = () => {
        uploadedStickers.forEach((item) => {
            if (item.file) {
                addStickerLibrary(item.file)
                    .then(async (res) => {
                        if (res) {
                            res.data.base64Image = item.base64Sticker;
                            setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
                            setLibraryStickers((prevFiles) => [...prevFiles, res.data]);
                            toast.success("Request success");
                        }
                    })
                    .catch((e) => {
                        toast.warning("Server error occurred");
                    });
            } else {
                setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
            }
        });
    };

    const addOthers = () => {
        uploadedOthers.forEach((item) => {
            if (item.file) {
                addOtherLibrary(item.file)
                    .then(async (res) => {
                        if (res) {
                            res.data.base64Image = item.base64Sticker;
                            setUploadedOthers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
                            setLibraryStickers((prevFiles) => [...prevFiles, res.data]);
                            toast.success("Request success");
                        }
                    })
                    .catch((e) => {
                        toast.warning("Server error occurred");
                    });
            } else {
                setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
            }
        });
    };

    const deleteSticker = async (id: number) => {
        await deleteStickerLibrary(id)
            .then((res) => {
                if (res) {
                    setLibraryStickers((prevFiles) => prevFiles.filter((file) => file.id !== id));
                    toast.success(res.data);
                } else {
                    toast.warning("System Error");
                }
            })
            .catch((e) => toast.warning("Server error occured"));
    };

    const deleteUploadedSticker = (id: number) => {
        setUploadedStickers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== id));
        if (id) {
            toast.success("Delete Success.");
        }
    };

    const deleteUploadedVoid = (id: number) => {
        setUploadedVoid((prevFiles) => prevFiles.filter((file) => file.preUploadId !== id));
        if (id) {
            toast.success("Delete Success.");
        }
    };

    const deleteUploadedOther = (id: number) => {
        setUploadedOthers((prevFiles) => prevFiles.filter((file) => file.preUploadId !== id));
        if (id) {
            toast.success("Delete Success.");
        }
    };

    const handleUploadSticker = async (file: File) => {
        const maxId = Math.max(...uploadedStickers.map((sticker) => sticker.preUploadId ?? 0));

        let model = new StickerLibraryDto();
        model.preUploadId = maxId > 0 ? maxId + 1 : 1;
        model.file = file;
        model.isPreUploaded = true;

        model.base64Sticker = await fileToBase64(file);

        setUploadedStickers((prevFiles) => [...prevFiles, model]);
    };

    const handleUploadOther = async (file: File) => {
        const maxId = Math.max(...uploadedOthers.map((sticker) => sticker.preUploadId ?? 0));

        let model = new StickerLibraryDto();
        model.preUploadId = maxId > 0 ? maxId + 1 : 1;
        model.file = file;
        model.isPreUploaded = true;

        model.base64Sticker = await fileToBase64(file);

        setUploadedOthers((prevFiles) => [...prevFiles, model]);
    };

    const handleUploadVoid = async (file: File) => {
        const maxId = Math.max(...uploadedVoid.map((sticker) => sticker.preUploadId ?? 0));

        let model = new StickerLibraryDto();
        model.preUploadId = maxId > 0 ? maxId + 1 : 1;
        model.file = file;
        model.isPreUploaded = true;

        model.base64Sticker = await fileToBase64(file);

        setUploadedVoid((prevFiles) => [...prevFiles, model]);
    };

    const clearUploadedVoid = () => {
        setUploadedVoid([]);
    };

    const clearUploadedSticker = () => {
        setUploadedStickers([]);
    };

    const clearUploadedOthers = () => {
        setUploadedOthers([]);
    };

    const handleSelectStickerToEdit = (sticker: StickerLibraryDto | null) => {
        if (!showLoader) {
            if (sticker !== null) {
                setSelectedStickerToEdit(sticker.base64Sticker);
            } else {
                setSelectedStickerToEdit(null);
            }
        }
    };

    return (
        <StickerLibraryContext.Provider
            value={{
                getTrump,
                getSixTrump,
                addTrump,
                getStickers,
                getSixOthers,
                libraryStickers,
                uploadedStickers,
                uploadedOthers,
                uploadedTrump,
                uploadedVoid,
                addStickers,
                addOthers,
                deleteSticker,
                handleUploadSticker,
                handleSelectStickerToEdit,
                handleUploadOther,
                handleUploadTrump,
                selectedStickerToEdit,
                clearUploadedSticker,
                clearUploadedTrump,
                deleteUploadedSticker,
                deleteUploadedTrump,
                deleteUploadedOther,
                clearUploadedOthers,
                getOthers,
                getSixStickers,
                getVoid,
                getSixVoid,
                addVoid,
                deleteUploadedVoid,
                clearUploadedVoid,
                handleUploadVoid,
            }}
        >
            {children}
        </StickerLibraryContext.Provider>
    );
};

export const useStickersLibrary = () => React.useContext(StickerLibraryContext);

export interface ISticker {
    id: number;
    base64Sticker: string;
    flipX: boolean;
    flipY: boolean;
    transform: string;
}
