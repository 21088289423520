import { useEffect, useRef } from "react";
import { CanvasSizeOptions, CanvasSizeType } from "./CanvasSizeData";
import { useCanvasSize } from "../../../../Context/useCanvasSize";

type Props = {
    closeCanvasSize: () => void;
    buttonRef: React.RefObject<HTMLButtonElement>;
};

const CanvasSize = ({ closeCanvasSize, buttonRef }: Props) => {
    const { selectedCanvasSize, handleSetSelectedCanvasSize } = useCanvasSize();
    const canvasSizeRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (canvasSizeRef.current && !canvasSizeRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                closeCanvasSize();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={canvasSizeRef} className="bg-bg_light_primary dark:bg-bg_dark_third border border-modal_light_border dark:border-bg_dark_secondary rounded-t-3xl lg:rounded-lg p-4">
                <div className="flex items-center justify-between w-full py-6 px-4 lg:py-2 lg:px-3">
                    <p className="uppercase text-lg font-black text-text_light_secondary dark:text-white">choose canvas size</p>

                    <svg onClick={closeCanvasSize} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-text_light_secondary dark:text-white">
                        <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="flex flex-col gap-2 w-full lg:w-max">
                    {CanvasSizeOptions.filter((c) => c.type !== CanvasSizeType.NOT_SET).map((item, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                handleSetSelectedCanvasSize(item);
                                closeCanvasSize();
                            }}
                            className={`flex justify-between items-center ${
                                selectedCanvasSize.type === item.type ? "bg-hr_light dark:bg-text_light_secondary" : "bg-modal_light_border dark:bg-bg_dark_secondary hover:bg-bg_hover"
                            }  border border-modal_light_border dark:border-[#222A3C] w-full lg:w-72 rounded-lg py-2 px-4 cursor-pointer `}
                        >
                            <div className="flex items-center gap-2 ">
                                <div
                                    className={`flex items-center justify-center ${
                                        selectedCanvasSize.type === item.type ? "bg-button_primary" : "dark:bg-text_light_secondary bg-hr_light"
                                    } rounded w-6 h-6`}
                                >
                                    {item.icon}
                                </div>
                                <p className={`${selectedCanvasSize.type === item.type ? "text-white" : "text-text_light_secondary dark:text-white"}`}>{item.title}</p>
                            </div>
                            <p className={`font-medium text-xs ${selectedCanvasSize.type === item.type ? "text-white" : "text-text_light_third dark:text-text_dark_third"}`}>{item.secondaryTitle}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default CanvasSize;
