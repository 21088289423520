import React, { createContext, useContext, useRef, ReactNode, useState } from "react";

interface GlobalActionsContextType {
    saveImage: () => void;
    setSaveImage: (fn: () => void) => void;
    handleSetDownloadType: (imgTpe: string) => void;
    downloadType: string;
    shareOnX: () => Promise<boolean>;
    setShareOnX: (fn: () => Promise<boolean>) => void;
    scale: number;
    handleSetScale: (scale: number) => void;
    showLoader: boolean;
    handleSeShowLoader: (state: boolean) => void;
    handleEnableDisableMovement: (state: boolean) => void;
    isMovable: boolean;
}

const GlobalActionsContext = createContext<GlobalActionsContextType | undefined>(undefined);

export const GlobalActionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [downloadType, setDownloadType] = useState<string>("png");
    const saveImageRef = useRef<() => void>(() => {});
    const shareOnXRef = useRef<() => Promise<boolean>>(() => Promise.resolve(false));
    const [scale, setScale] = useState<number>(1);
    const [showLoader, seShowLoader] = useState(false);
    const [isMovable, setIsMovable] = useState<boolean>(false);

    const handleEnableDisableMovement = (state: boolean) => {
        // unselectEverything();
        setIsMovable(state);
    };

    const handleSeShowLoader = (state: boolean) => {
        seShowLoader(state);
    };

    const handleSetScale = (scale: number) => {
        setScale(scale);
    };

    const setSaveImage = (fn: () => void) => {
        saveImageRef.current = fn;
    };

    const saveImage = () => {
        if (saveImageRef.current) {
            saveImageRef.current();
        }
    };

    const handleSetDownloadType = (downloadType: string) => {
        setDownloadType(downloadType);
    };

    const setShareOnX = (fn: () => Promise<boolean>) => {
        shareOnXRef.current = fn;
    };

    const shareOnX = async () => {
        if (shareOnXRef.current) {
            return await shareOnXRef.current();
        }
        return false;
    };

    return (
        <GlobalActionsContext.Provider
            value={{
                saveImage,
                setSaveImage,
                handleSetDownloadType,
                downloadType,
                shareOnX,
                setShareOnX,
                handleSetScale,
                scale,
                handleEnableDisableMovement,
                isMovable,
                showLoader,
                handleSeShowLoader,
            }}
        >
            {children}
        </GlobalActionsContext.Provider>
    );
};

export const useGlobalActions = () => {
    const context = useContext(GlobalActionsContext);
    if (!context) {
        throw new Error("useGlobalActions must be used within a GlobalActionsProvider");
    }
    return context;
};
