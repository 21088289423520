import { useTemplateLibrary } from "../../../Context/useTemplateLibrary";
import { useStickersLibrary } from "../../../Context/useStickerLibrary";
import { useEffect, useRef, useState } from "react";
import ColorPickerCustom from "../../Common/ColorPicker/ColorPicker";
import { CanvasSizeOptions } from "./CanvasSize/CanvasSizeData";
import CanvasSize from "./CanvasSize/CanvasSize";
import { useCanvasSize } from "../../../Context/useCanvasSize";
import { AddText, ArrowIcon, Delete, DownloadIcon, Flip, MouseCursor, MoveCursor, RedoIcon, ResetIcon, ResetZoom, TextIcon, UndoIcon, ZoomIn, ZoomOut } from "../../Common/Icons";
import TextEditor from "./TextEditor/TextEditor";
import { useGlobalActions } from "../../../Context/useGlobalActionsContext";
import { useTextsLibrary } from "../../../Context/useTextLibrary";
import ZoomSlider from "./ZoomSlider/ZoomSlider";

type Props = {
    className: string;
    handleFlipX: () => void;
    handleFlipY: () => void;
    handleText: () => void;
    handleDeleteClick: () => void;
    undo: () => void;
    redo: () => void;
    zoomIn: () => void;
    zoomOut: () => void;
};

const ControlPanelMobile = ({ className, handleFlipX, handleFlipY, handleText, handleDeleteClick, undo, redo, zoomIn, zoomOut }: Props) => {
    const { handleSetScale, scale, handleEnableDisableMovement, isMovable, saveImage, showLoader } = useGlobalActions();
    const { saveResultImage, handleSelectImageToEdit } = useTemplateLibrary();
    const { handleSelectStickerToEdit } = useStickersLibrary();
    const { reset } = useTextsLibrary();
    const { selectedCanvasSize, handleSetSelectedCanvasSize } = useCanvasSize();
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showCanvasSize, setShowCanvasSize] = useState(false);
    const [showTextEditor, setShowTextEditor] = useState(false);
    const colorPickerRef = useRef<HTMLDivElement>(null);
    const colorPickerButtonRef = useRef<HTMLButtonElement>(null);
    const canvasSizeRef = useRef<HTMLDivElement>(null);
    const canvasButtonRef = useRef<HTMLButtonElement>(null);
    const textEditorRef = useRef<HTMLDivElement>(null);
    const buttonTexEditorRef = useRef<HTMLButtonElement>(null);
    const [toggle, setToggle] = useState(false);

    const handleSetToggle = () => {
        setToggle(!toggle);
    };
    const handleReset = () => {
        saveResultImage(null);
        handleSelectImageToEdit(null, false);
        handleSelectStickerToEdit(null);
        handleSetSelectedCanvasSize(CanvasSizeOptions[0]);
        handleSetScale(1);
        handleEnableDisableMovement(false);
        reset();
    };

    const handleShowTextEditor = () => {
        setShowTextEditor(!showTextEditor);
    };

    const closeTextEditor = () => {
        setShowTextEditor(false);
    };

    const handleShowColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    };

    const handleShowCanvasSize = () => {
        setShowCanvasSize(!showCanvasSize);
    };

    const closeCanvasSize = () => {
        setShowCanvasSize(false);
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target) && colorPickerButtonRef.current && !colorPickerButtonRef.current.contains(event.target as Node)) {
                setShowColorPicker(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className={`flex xl:hidden flex-col  ${showCanvasSize || showTextEditor ? "z-[300] lg:z-0" : "z-[100] lg:z-0"}`}>
                <div className={`flex  justify-between items-center gap-2 bg-bg_light_third dark:bg-bg_dark_secondary px-4   min-h-14 w-full ${showCanvasSize ? "z-[100] lg:z-0" : "z-20 lg:z-0"} `}>
                    <div className="flex items-center justify-between md:justify-center md:gap-6 w-full ">
                        <div className="flex gap-5">
                            <button onClick={undo} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <UndoIcon color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <button onClick={redo} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <RedoIcon color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                        </div>

                        <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />

                        <div className="lg:relative">
                            <button
                                ref={canvasButtonRef}
                                onClick={handleShowCanvasSize}
                                className="flex items-center gap-2 font-medium dark:text-text_dark_primary hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1"
                            >
                                {CanvasSizeOptions.find((c) => c.type === selectedCanvasSize.type)?.titleOnControlPanelMobile}
                            </button>
                            <div ref={canvasSizeRef} className={`fixed right-0 xl:right-auto w-full lg:w-auto bottom-0 lg:top-10 z-50 ${showCanvasSize ? "" : "hidden"} shadow-canvas_size_menu`}>
                                <CanvasSize buttonRef={canvasButtonRef} closeCanvasSize={closeCanvasSize} />
                            </div>
                        </div>

                        <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />

                        <div className="flex gap-5">
                            <button
                                onClick={handleReset}
                                className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] dark:text-text_dark_primary rounded-lg p-1 uppercase font-medium text-text_light_primary"
                            >
                                <ResetIcon color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <button onClick={handleDeleteClick} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <Delete color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <button onClick={saveImage} disabled={showLoader} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <DownloadIcon color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`flex  justify-between items-center gap-2 bg-bg_light_third dark:bg-bg_dark_secondary px-4  sm:rounded-b-lg min-h-14 w-full ${showCanvasSize ? "z-[100]" : "z-20"} ${
                        toggle ? "" : "hidden sm:flex"
                    }`}
                >
                    <div className="flex items-center justify-between md:justify-center md:gap-6 w-full ">
                        <div className="lg:relative flex gap-5">
                            <button onClick={handleText} ref={colorPickerButtonRef} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <AddText color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <button ref={buttonTexEditorRef} onClick={handleShowTextEditor} className="flex items-center gap-1 hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <TextIcon color="text-text_light_primary dark:text-text_dark_primary" />
                                <ArrowIcon
                                    width="14"
                                    height="8"
                                    color={` transition-transform transform ${showTextEditor ? "rotate-180" : "rotate-0"}  text-text_light_primary dark:text-text_dark_primary`}
                                />
                            </button>
                            <div ref={textEditorRef} className={`fixed lg:absolute bottom-0 left-0 lg:left-auto w-full lg:w-auto lg:bottom-auto lg:top-10 z-100 ${showTextEditor ? "" : "hidden"}`}>
                                <TextEditor buttonRef={buttonTexEditorRef} closeTextEditor={closeTextEditor} />
                            </div>
                        </div>

                        <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />

                        <div className="flex gap-5">
                            <button onClick={handleFlipX} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <Flip color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <button onClick={handleFlipY} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1 rotate-90">
                                <Flip color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                        </div>
                        <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none" />
                        <div className="flex gap-5">
                            <button onClick={zoomOut} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <ZoomOut color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <button onClick={zoomIn} className="hover:bg-[#C3C1C8] dark:hover:bg-[#63697B] rounded-lg p-1">
                                <ZoomIn color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`hidden lg:flex justify-between items-center gap-2 xl:hidden w-full lg:w-fit lg:rounded-lg self-end  py-2 px-4  z-50 bg-bg_light_third dark:bg-bg_dark_secondary`}>
                    <button
                        onClick={() => {
                            handleSetScale(1);
                        }}
                        title="Reset Canvas Zoom"
                        className={`${scale !== 1 ? "hover:bg-[#C3C1C8] dark:hover:bg-[#63697B]" : ""} rounded-lg p-1 `}
                        disabled={scale === 1}
                    >
                        <ResetZoom color={`${scale === 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                    </button>
                    <ZoomSlider />

                    <button
                        onClick={() => {
                            handleEnableDisableMovement(!isMovable);
                        }}
                        title="Move canvas"
                        className={`${!isMovable && scale <= 1 ? "hover:bg-[#C3C1C8] dark:hover:bg-[#63697B]" : ""} rounded-lg p-1 `}
                        disabled={!isMovable && scale <= 1}
                    >
                        {!isMovable ? (
                            <MoveCursor color={`${!isMovable && scale <= 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                        ) : (
                            <MouseCursor color={`${!isMovable && scale <= 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                        )}
                    </button>
                </div>
                <div onClick={handleSetToggle} className={`flex sm:hidden justify-center bg-[#DFDEDF] dark:bg-[#c3c1c85e] ${showCanvasSize ? "z-[100]" : "z-20"}`}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`${toggle ? "rotate-180" : ""} transition-transform duration-300 text-text_light_primary dark:text-text_dark_primary`}
                    >
                        <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
        </>
    );
};

export default ControlPanelMobile;
