import { createContext, useEffect, useState } from "react";
import { UserProfile } from "../Models/User";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../Services/AuthService";
import { toast } from "react-toastify";
import React from "react";
import { setAuthToken } from "../Lib/axiosConfig";

type UserContextType = {
    user: UserProfile | null;
    token: string | null;
    loginUser: (password: string) => void;
    logout: () => void;
    isLoggedIn: () => boolean;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem("user");
        const token = localStorage.getItem("token");
        if (user && token) {
            setUser(JSON.parse(user));
            setToken(token);
            setAuthToken(token);
        } else {
            setAuthToken(null);
        }
        setIsReady(true);
    }, []);

    const loginUser = async (password: string) => {
        await loginAPI(password)
            .then((res) => {
                if (res) {
                    localStorage.setItem("token", res?.data.data.accessToken);
                    const userObj = {
                        userName: res?.data.data.userName,
                    };
                    localStorage.setItem("user", JSON.stringify(userObj));
                    setToken(res?.data.data.accessToken!);
                    setUser(userObj!);
                    setAuthToken(res?.data.data.accessToken!);
                    toast.success("Login Success!");
                    navigate("/Admin");
                }
            })
            .catch((e) => toast.warning("Server error occured"));
    };

    const isLoggedIn = () => {
        return !!user;
    };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setUser(null);
        setToken("");
        setAuthToken(null);
        navigate("/");
    };

    return <UserContext.Provider value={{ loginUser, user, token, logout, isLoggedIn }}>{isReady ? children : null}</UserContext.Provider>;
};

export const useAuth = () => React.useContext(UserContext);
