import "./switchTheme.css";
import { useDarkMode } from "../../../Hooks/useDarkMode";
import cloud_1 from "./cloud_1.svg";
import cloud_2 from "./cloud_1.svg";
import cloud_3 from "./cloud_1.svg";
import cloud_4 from "./cloud_1.svg";
import stars from "./stars.svg";

const SwitchTheme = () => {
    const [darkMode, setDarkMode] = useDarkMode();

    const handleDarkMode = () => {
        setDarkMode(!darkMode);
    };
    return (
        <div id="switchThemeMode" className="h-7 lg:h-auto">
            <label className="switch h-7 lg:h-auto">
                <input type="checkbox" id="switch" onChange={handleDarkMode} />
                <div className="sunmoon h-6 w-6 lg:h-[26px] lg:w-[26px]">
                    <div className="darkside"></div>
                </div>
                <div className="border"></div>
                <div className="clouds">
                    <img src={cloud_1} alt="" className="cloud cloud_1" />
                    <img src={cloud_2} alt="" className="cloud cloud_2" />
                    <img src={cloud_3} alt="" className="cloud cloud_3" />
                    <img src={cloud_4} alt="" className="cloud cloud_4" />
                    <img src={stars} alt="" className="stars" />
                </div>
            </label>
        </div>
    );
};

export default SwitchTheme;
