import React, { useEffect, useRef, useState } from "react";

import { ArrowIcon } from "../../../Common/Icons";
import { useTextsLibrary } from "../../../../Context/useTextLibrary";

export class FontOption {
    font!: AvailableFonts;
}

export enum AvailableFonts {
    MONTSERRAT = "Montserrat",
    CORMOTANT = "Cormotant",
    DM_SANS = "Dm Sans",
    SPACE_MONO = "Space Mono",
    WORK_SANS = "Work Sans",
    SYNE = "Syne",
}

interface FontsDropDownProps {
    className?: string;
}

const FontsDropDown: React.FC<FontsDropDownProps> = ({ className }) => {
    const { handleSetTextStyle, currentTextStyle } = useTextsLibrary();
    const [isOpen, setIsOpen] = useState(false);
    const fontRef = useRef<HTMLDivElement>(null);
    const buttonFontRef = useRef<HTMLButtonElement>(null);
    const { selectedFont, handleSetSelectedFont } = useTextsLibrary();
    const downloadTypeOptions: FontOption[] = [
        { font: AvailableFonts.MONTSERRAT },
        { font: AvailableFonts.CORMOTANT },
        { font: AvailableFonts.DM_SANS },
        { font: AvailableFonts.SPACE_MONO },
        { font: AvailableFonts.WORK_SANS },
        { font: AvailableFonts.SYNE },
    ];

    const handleChangeFont = (font: AvailableFonts) => {
        let current = currentTextStyle;
        if (current) {
            current.font = font;
            handleSetTextStyle(current);
        }
    };

    const handleOptionClick = (option: FontOption) => {
        setIsOpen(false);
        handleSetSelectedFont(option.font);
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (fontRef.current && !fontRef.current.contains(event.target) && buttonFontRef.current && !buttonFontRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={fontRef} className="relative py-2 px-4 rounded-lg bg-white dark:bg-bg_dark_secondary w-full min-w-48 border border-modal_light_border dark:border-bg_dark_secondary">
            <button ref={buttonFontRef} type="button" className="flex justify-between items-center font-sen text-base text-black leading-4 px-4 w-full " onClick={() => setIsOpen(!isOpen)}>
                <div className="flex font-black text-base text-black dark:text-white w-full">{currentTextStyle?.font ?? selectedFont}</div>
                <ArrowIcon color={`ml-2 transition-transform transform ${isOpen ? "rotate-180" : "rotate-0"} text-black dark:text-white`} />
            </button>
            {isOpen && (
                <ul className="flex flex-col gap-5 absolute z-10 bottom-8 mb-3 lg:bottom-auto left-0 lg:mt-3 bg-white dark:bg-bg_dark_secondary border border-modal_light_border dark:border-bg_dark_secondary  p-4 rounded-lg  w-full">
                    {downloadTypeOptions.map((option, index) => (
                        <li
                            key={index}
                            className="flex justify-between cursor-pointer"
                            onClick={() => {
                                handleOptionClick(option);
                                handleChangeFont(option.font);
                            }}
                        >
                            <div
                                className={`flex gap-2 dark:text-white ${selectedFont === option.font ? "font-black" : ""} 
                            ${
                                option.font === AvailableFonts.CORMOTANT
                                    ? "font-cormorant"
                                    : option.font === AvailableFonts.MONTSERRAT
                                    ? "font-montserrat"
                                    : option.font === AvailableFonts.DM_SANS
                                    ? "font-dm_sans"
                                    : option.font === AvailableFonts.SPACE_MONO
                                    ? "font-space_mono"
                                    : option.font === AvailableFonts.SYNE
                                    ? "font-syne"
                                    : option.font === AvailableFonts.WORK_SANS
                                    ? "font-work_sans"
                                    : ""
                            }
                            
                            `}
                            >
                                {option.font}
                            </div>
                            {selectedFont === option.font && (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                    <path d="M20 6L9 17L4 12" stroke="#F67D52" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FontsDropDown;
