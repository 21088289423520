import Logo from "./logo.png";
import Share from "./share.svg";
import { useAuth } from "../../Context/useAuth";
import SwitchTheme from "./SwitchTheme/SwitchTheme";
import ShareModal from "./ShareMemeModal/ShareModal";
import { useRef, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import { DownloadIcon } from "../Common/Icons";
import { useGlobalActions } from "../../Context/useGlobalActionsContext";

const Header = () => {
    const { isLoggedIn, logout } = useAuth();
    const [toggleShareModal, setToggleShareModal] = useState<boolean>(false);
    const [toggleConfirmModal, setToggleConfirmModal] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { saveImage } = useGlobalActions();

    const handleSetToggleShareModal = () => {
        if (toggleShareModal === true) {
            setToggleShareModal(false);
        } else {
            setToggleShareModal(true);
        }
    };

    return (
        <>
            <div className="flex justify-center items-center  px-6 w-full xl:relative bg-bg_Light_secondary dark:bg-bg_dark_secondary shadow-header_shadow">
                <div className="flex justify-between my-4 w-full">
                    <a href="#" onClick={() => setToggleConfirmModal(!toggleConfirmModal)} className="flex items-center gap-6">
                        <img src={Logo} alt="Logo" className="w-[54px] h-11 lg:h-[50px] lg:w-[62px]" />
                    </a>

                    <ConfirmModal setToggleConfirmModal={setToggleConfirmModal} show={toggleConfirmModal} />

                    <div className="flex items-center gap-3 lg:relative">
                        {isLoggedIn() && (
                            <button onClick={() => logout()} className="flex items-center justify-center bg-bg_light_primary text-[#3D465D] rounded-lg w-[93px] h-7 text-sm">
                                LOGOUT
                            </button>
                        )}

                        <SwitchTheme />

                        <button
                            ref={buttonRef}
                            id="open-modal"
                            onClick={() => handleSetToggleShareModal()}
                            className="flex justify-center items-center gap-2 bg-white px-8 py-3 rounded-lg text-sm lg:text-base"
                        >
                            <img src={Share} className="w-6 h-6" alt="share" /> SHARE ON X
                        </button>

                        <ShareModal buttonRef={buttonRef} setToggleShareModal={setToggleShareModal} toggleShareModal={toggleShareModal} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
