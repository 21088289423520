import { useEffect, useRef, useState } from "react";
import OthersMobile from "./Others/OthersMobile";
import MemesMobile from "./Memes/MemesMobile";
import StickersMobile from "./Stickers/StickersMobile";
import WallpapersMobile from "./Wallpapers/WallpapersMobile";
import BannersMobile from "./Banners/BannersMobile";
import { useTemplateLibrary } from "../../Context/useTemplateLibrary";
import { useStickersLibrary } from "../../Context/useStickerLibrary";
import { ResetZoom, MoveCursor, MouseCursor } from "../Common/Icons";
import ZoomSlider from "../MainArea/ControlPanel/ZoomSlider/ZoomSlider";
import { useGlobalActions } from "../../Context/useGlobalActionsContext";
import TrumpMobile from "./Trump/TrumpMobile";
import VoidsMobile from "./Void/VoidsMobile";

type Props = {
    className: string;
};

export enum LeftSideMobileItems {
    MEMES = 1,
    STICKERS = 2,
    OTHERS = 3,
    WALLPAPERS = 4,
    BANNERS = 5,
    TRUMP = 6,
    VOID = 7,
}

const LeftSideMobile = () => {
    const [showMenu, setShowMenu] = useState<LeftSideMobileItems | null>(null);
    const { getTemplates } = useTemplateLibrary();
    const { getOthers, getStickers, getTrump, getVoid } = useStickersLibrary();
    const { handleSetScale, scale, handleEnableDisableMovement, isMovable, saveImage, showLoader } = useGlobalActions();
    const buttonMemesRef = useRef<HTMLButtonElement>(null);
    const buttonWallpapersRef = useRef<HTMLButtonElement>(null);
    const buttonBannersRef = useRef<HTMLButtonElement>(null);
    const buttonStickersRef = useRef<HTMLButtonElement>(null);
    const buttonOthersRef = useRef<HTMLButtonElement>(null);
    const buttonVoidsRef = useRef<HTMLButtonElement>(null);
    const [toggle, setToggle] = useState(false);

    const handleSetToggle = () => {
        setToggle(!toggle);
    };
    useEffect(() => {
        getTemplates("memes");

        getTemplates("wallpapers");

        getTemplates("banners");
        getOthers();
        getStickers();
        getTrump();
        getVoid();
    }, []);

    const handleCloseMenu = (item?: LeftSideMobileItems) => {
        if (showMenu === item) {
            setShowMenu(null);
        } else if (item) {
            setShowMenu(item);
        } else {
            setShowMenu(null);
        }
    };

    return (
        <>
            <div className="w-full fixed bottom-0 lg:hidden z-[200]">
                <div onClick={handleSetToggle} className={`flex justify-center bg-[#DFDEDF] dark:bg-[#c3c1c85e] `}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`${toggle ? "" : "rotate-180"} transition-transform duration-300 text-text_light_primary dark:text-text_dark_primary`}
                    >
                        <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div className={`flex justify-between items-center gap-2 xl:hidden w-full self-end  py-2 px-4  z-50 bg-bg_light_third dark:bg-bg_dark_secondary`}>
                    <button
                        onClick={() => {
                            handleSetScale(1);
                        }}
                        title="Reset Canvas Zoom"
                        className={`${scale !== 1 ? "hover:bg-[#C3C1C8] dark:hover:bg-[#63697B]" : ""} rounded-lg p-1 `}
                        disabled={scale === 1}
                    >
                        <ResetZoom color={`${scale === 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                    </button>
                    <ZoomSlider />

                    <button
                        onClick={() => {
                            handleEnableDisableMovement(!isMovable);
                        }}
                        title="Move canvas"
                        className={`${!isMovable && scale <= 1 ? "hover:bg-[#C3C1C8] dark:hover:bg-[#63697B]" : ""} rounded-lg p-1 `}
                        disabled={!isMovable && scale <= 1}
                    >
                        {!isMovable ? (
                            <MoveCursor color={`${!isMovable && scale <= 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                        ) : (
                            <MouseCursor color={`${!isMovable && scale <= 1 ? "text-[#c3c1c85e]" : "text-text_light_primary dark:text-text_dark_primary"} `} />
                        )}
                    </button>
                </div>
                {toggle && (
                    <>
                        <div className="relative ">
                            {showMenu === LeftSideMobileItems.MEMES && (
                                <MemesMobile
                                    buttonRef={buttonMemesRef}
                                    closeMenu={handleCloseMenu}
                                    className="absolute flex flex-col gap-3 w-full bottom-0 bg-bg_light_primary dark:bg-bg_dark_third pt-6 px-4 pb-3 rounded-t-3xl"
                                ></MemesMobile>
                            )}
                            {showMenu === LeftSideMobileItems.WALLPAPERS && (
                                <WallpapersMobile
                                    buttonRef={buttonWallpapersRef}
                                    closeMenu={handleCloseMenu}
                                    className="absolute flex flex-col gap-3 w-full bottom-0 bg-bg_light_primary dark:bg-bg_dark_third pt-6 px-4 pb-3 rounded-t-3xl"
                                ></WallpapersMobile>
                            )}

                            {showMenu === LeftSideMobileItems.BANNERS && (
                                <BannersMobile
                                    buttonRef={buttonBannersRef}
                                    closeMenu={handleCloseMenu}
                                    className="absolute flex flex-col gap-3 w-full bottom-0 bg-bg_light_primary dark:bg-bg_dark_third pt-6 px-4 pb-3 rounded-t-3xl"
                                ></BannersMobile>
                            )}
                            {showMenu === LeftSideMobileItems.TRUMP && (
                                <TrumpMobile
                                    buttonRef={buttonStickersRef}
                                    closeMenu={handleCloseMenu}
                                    className="absolute flex flex-col w-full bottom-0 bg-bg_light_primary dark:bg-bg_dark_third pt-6 px-4 pb-3 rounded-t-3xl"
                                ></TrumpMobile>
                            )}
                            {showMenu === LeftSideMobileItems.STICKERS && (
                                <StickersMobile
                                    buttonRef={buttonStickersRef}
                                    closeMenu={handleCloseMenu}
                                    className="absolute flex flex-col w-full bottom-0 bg-bg_light_primary dark:bg-bg_dark_third pt-6 px-4 pb-3 rounded-t-3xl"
                                ></StickersMobile>
                            )}
                            {showMenu === LeftSideMobileItems.OTHERS && (
                                <OthersMobile
                                    buttonRef={buttonOthersRef}
                                    closeMenu={handleCloseMenu}
                                    className="absolute flex flex-col w-full bottom-0 bg-bg_light_primary dark:bg-bg_dark_third pt-6 px-4 pb-3 rounded-t-3xl"
                                />
                            )}
                            {showMenu === LeftSideMobileItems.VOID && (
                                <VoidsMobile
                                    buttonRef={buttonVoidsRef}
                                    closeMenu={handleCloseMenu}
                                    className="absolute flex flex-col w-full bottom-0 bg-bg_light_primary dark:bg-bg_dark_third pt-6 px-4 pb-3 rounded-t-3xl"
                                />
                            )}
                        </div>
                        <div className={`flex items-center justify-between sm:justify-center gap-1 overflow-x-auto w-screen py-6 px-8 bg-white dark:bg-bg_dark_third `}>
                            <button
                                ref={buttonMemesRef}
                                onClick={() => handleCloseMenu(LeftSideMobileItems.MEMES)}
                                className={`py-2 px-4 rounded-lg min-w-fit ${
                                    showMenu === LeftSideMobileItems.MEMES
                                        ? "bg-hr_light dark:bg-text_light_secondary text-white"
                                        : "bg-bg_light_third dark:bg-bg_dark_secondary text-text_light_primary dark:text-text_dark_primary"
                                } uppercase font-medium text-sm `}
                            >
                                Memes
                            </button>
                            <button
                                ref={buttonWallpapersRef}
                                onClick={() => handleCloseMenu(LeftSideMobileItems.WALLPAPERS)}
                                className={`py-2 px-4 rounded-lg min-w-fit ${
                                    showMenu === LeftSideMobileItems.WALLPAPERS
                                        ? "bg-hr_light dark:bg-text_light_secondary text-white"
                                        : "bg-bg_light_third dark:bg-bg_dark_secondary text-text_light_primary dark:text-text_dark_primary"
                                } uppercase font-medium text-sm `}
                            >
                                WALLPAPERS
                            </button>
                            <button
                                ref={buttonBannersRef}
                                onClick={() => handleCloseMenu(LeftSideMobileItems.BANNERS)}
                                className={`py-2 px-4 rounded-lg min-w-fit ${
                                    showMenu === LeftSideMobileItems.BANNERS
                                        ? "bg-hr_light dark:bg-text_light_secondary text-white"
                                        : "bg-bg_light_third dark:bg-bg_dark_secondary text-text_light_primary dark:text-text_dark_primary"
                                } uppercase font-medium text-sm `}
                            >
                                BANNERS
                            </button>

                            <button
                                ref={buttonOthersRef}
                                onClick={() => handleCloseMenu(LeftSideMobileItems.OTHERS)}
                                className={`py-2 px-4 rounded-lg  min-w-fit ${
                                    showMenu === LeftSideMobileItems.OTHERS
                                        ? "bg-hr_light dark:bg-text_light_secondary text-white"
                                        : "bg-bg_light_third dark:bg-bg_dark_secondary text-text_light_primary dark:text-text_dark_primary"
                                } uppercase font-medium text-sm `}
                            >
                                sparky
                            </button>

                            <button
                                ref={buttonVoidsRef}
                                onClick={() => handleCloseMenu(LeftSideMobileItems.VOID)}
                                className={`py-2 px-4 rounded-lg  min-w-fit ${
                                    showMenu === LeftSideMobileItems.VOID
                                        ? "bg-hr_light dark:bg-text_light_secondary text-white"
                                        : "bg-bg_light_third dark:bg-bg_dark_secondary text-text_light_primary dark:text-text_dark_primary"
                                } uppercase font-medium text-sm `}
                            >
                                void
                            </button>
                            <button
                                ref={buttonStickersRef}
                                onClick={() => handleCloseMenu(LeftSideMobileItems.TRUMP)}
                                className={`py-2 px-4 rounded-lg min-w-fit ${
                                    showMenu === LeftSideMobileItems.TRUMP
                                        ? "bg-hr_light dark:bg-text_light_secondary text-white"
                                        : "bg-bg_light_third dark:bg-bg_dark_secondary text-text_light_primary dark:text-text_dark_primary"
                                } uppercase font-medium text-sm `}
                            >
                                Trump
                            </button>
                            <button
                                ref={buttonStickersRef}
                                onClick={() => handleCloseMenu(LeftSideMobileItems.STICKERS)}
                                className={`py-2 px-4 rounded-lg min-w-fit ${
                                    showMenu === LeftSideMobileItems.STICKERS
                                        ? "bg-hr_light dark:bg-text_light_secondary text-white"
                                        : "bg-bg_light_third dark:bg-bg_dark_secondary text-text_light_primary dark:text-text_dark_primary"
                                } uppercase font-medium text-sm `}
                            >
                                billy cat
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
export default LeftSideMobile;
