import { ChangeEvent, DragEvent, useEffect, useRef, useState } from "react";
import Upload from "./Upload.svg";
import { useTemplateLibrary } from "../../../Context/useTemplateLibrary";
import { TemplateLibraryDto } from "../../../Models/Library";
import { useCanvasSize } from "../../../Context/useCanvasSize";
import { CanvasSizeType } from "../ControlPanel/CanvasSize/CanvasSizeData";

type Props = {
    className: string;
};

const UploadArea = ({ className }: Props) => {
    const { selectedCanvasSize } = useCanvasSize();
    const { handleSelectImageToEdit } = useTemplateLibrary();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [x, setX] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [desktop, setDesktop] = useState(false);
    const [square, setSquare] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);

    useEffect(() => {
        setX(selectedCanvasSize.type === CanvasSizeType.TWITTER);
        setMobile(selectedCanvasSize.type === CanvasSizeType.MOBILE);
        setDesktop(selectedCanvasSize.type === CanvasSizeType.DESKTOP);
        setSquare(selectedCanvasSize.type === CanvasSizeType.SQUARE);
    }, [selectedCanvasSize]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result as string;
                let model: TemplateLibraryDto = {
                    base64Image: base64Image,
                    base64Thumbnail: base64Image,
                    id: 0,
                    file: undefined,
                    unit8ArrayImage: undefined,
                    isPreUploaded: false,
                    preUploadId: 0,
                    library_type: "",
                };
                handleSelectImageToEdit(model, true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false);

        const file = event.dataTransfer.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result as string;
                let model: TemplateLibraryDto = {
                    base64Image: base64Image,
                    base64Thumbnail: "",
                    id: 0,
                    file: undefined,
                    unit8ArrayImage: undefined,
                    isPreUploaded: false,
                    preUploadId: 0,
                    library_type: "",
                };
                handleSelectImageToEdit(model, true);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className={`${className}`}>
            <div
                className={`${
                    x
                        ? "w-[382px] h-[127px] lg:h-[319px] lg:w-[672px] xl:h-[319px] xl:w-[927px] desktop:h-[319px] desktop:w-[956px]"
                        : desktop
                        ? "w-[382px] h-[215px] lg:h-[537px] lg:w-[672px] xl:h-[537px] xl:w-[927px] desktop:h-[537px] desktop:w-[956px]"
                        : mobile
                        ? "w-[382px] h-[680px] lg:h-[550px] lg:w-[309px]"
                        : "w-[382px] h-[382px] lg:w-[550px] lg:h-[550px]"
                } flex flex-col justify-center items-center gap-8 lg:py-44 px-7 bg-bg_Light_secondary dark:bg-bg_dark_third shadow-upload_box_shadow dark:shadow-upload_box_shadow_dark ${
                    isDragOver ? "border-2 border-dashed border-blue-500" : ""
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <p className={`font-black ${mobile ? "text-xl w-max" : "text-2xl"} ${x ? "hidden lg:block" : ""} w-max lg:w-auto text-center uppercase tracking-[2.4px] dark:text-white`}>
                    Choose a template <br /> <span className="font-medium italic">or upload yours</span>
                    <br /> to start
                </p>
                <input type="file" accept="image/png, image/jpeg" onChange={handleFileChange} ref={fileInputRef} style={{ display: "none" }} />
                <button
                    onClick={handleUploadClick}
                    className={`flex justify-center items-center gap-2 ${mobile ? "w-max" : "w-fit"} ${x ? "hidden lg:flex" : ""} lg:w-auto py-2 px-4 rounded-lg bg-button_primary text-white`}
                >
                    <img src={Upload} alt="" /> Upload an image
                </button>
            </div>
        </div>
    );
};

export default UploadArea;
