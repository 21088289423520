import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import React from "react";
import { TemplateLibraryDto } from "../Models/Library";
import { addImageLibrary, deleteImageLibrary, getTemplate, findAll, findSix, getThumbnailTemplate } from "../Lib/apiTemplate";
import { fileToBase64 } from "../plugins/utils";
import { HttpStatusCode } from "axios";
import { useGlobalActions } from "./useGlobalActionsContext";

type TemplateLibraryContextType = {
    templateImages: TemplateLibraryDto[];
    uploadedImages: TemplateLibraryDto[];
    selectedImageToEdit: string | null;
    resultImage: string | null;
    getTemplates: (action: string) => void;
    getSixTemplates: (action: string) => void;
    addImages: () => void;
    deleteImage: (id: number) => void;
    deleteUploadedImage: (id: number) => void;
    clearUploadedImage: () => void;
    handleUploadImage: (file: File) => void;
    handleSelectImageToEdit: (image: TemplateLibraryDto | null, isEditImageAriaPage: boolean) => void;
    saveResultImage: (image: string | null) => void;
    finalImageBase64: string | null;
    handleSetFinalImageBase64: (finalImageBase64: string) => void;
    setUploadedImages: (images: TemplateLibraryDto[]) => void;
    isEditImageAriaPage: boolean;
    getOriginalTemplate: (id: number) => void;
};

type Props = { children: React.ReactNode };

const TemplateLibraryContext = createContext<TemplateLibraryContextType>({} as TemplateLibraryContextType);

export const ImagesLibraryProvider = ({ children }: Props) => {
    const { showLoader, handleSeShowLoader } = useGlobalActions();
    const [templateImages, setTemplateImages] = useState<TemplateLibraryDto[]>([]);

    const [uploadedImages, setUploadedImages] = useState<TemplateLibraryDto[]>([]);

    const [selectedImageToEdit, setSelectedImageToEdit] = useState<string | null>(null);

    const [resultImage, setResultImage] = useState<string | null>(null);

    const [finalImageBase64, setFinalImageBase64] = useState<string | null>(null);

    const [isEditImageAriaPage, setIsEditImageAriaPage] = useState<boolean>(false);

    const handleSetFinalImageBase64 = (finalImageBase64: string) => {
        setFinalImageBase64(finalImageBase64);
    };

    useEffect(() => {
        getSixTemplates("home-page-memes");
        getSixTemplates("home-page-wallpapers");
        getSixTemplates("home-page-banners");
    }, []);

    const getTemplates = async (action: string) => {
        try {
            const res = await findAll(action);
            if (res) {
                const templates = res.data;

                for (const item of templates) {
                    let dto: TemplateLibraryDto = new TemplateLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Thumbnail = item.thumbnail ?? "";

                    setTemplateImages((prevList) => {
                        const idMap = new Map();
                        prevList.forEach((existingItem) => idMap.set(existingItem.id, existingItem));
                        if (!idMap.has(dto.id)) {
                            idMap.set(dto.id, dto);
                        }
                        return Array.from(idMap.values());
                    });
                }
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const getSixTemplates = async (action: string) => {
        try {
            const res = await findSix(action);
            if (res) {
                const templates = res.data;
                for (const item of templates) {
                    let dto: TemplateLibraryDto = new TemplateLibraryDto();
                    dto.id = item.id;
                    dto.library_type = item.library_type;
                    dto.base64Thumbnail = item.thumbnail ?? "";
                    setTemplateImages((prevList) => {
                        const idMap = new Map();
                        prevList.forEach((existingItem) => idMap.set(existingItem.id, existingItem));
                        if (!idMap.has(dto.id)) {
                            idMap.set(dto.id, dto);
                        }
                        return Array.from(idMap.values());
                    });
                }
            }
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const getOriginalTemplate = async (id: number) => {
        try {
            const imageFile = await getTemplate(id);

            const binary = new Uint8Array(imageFile);
            let binaryString = "";
            for (let i = 0; i < binary.length; i++) {
                binaryString += String.fromCharCode(binary[i]);
            }
            const base64Image = `data:${imageFile.mime_type};base64,${btoa(binaryString)}`;
            setSelectedImageToEdit(base64Image);
            setTemplateImages((prevList) => {
                const updatedList = prevList.map((item) => {
                    if (item.id === id) {
                        return { ...item, base64Image };
                    }
                    return item;
                });
                return updatedList;
            });
        } catch (e) {
            toast.warning("Server error occurred");
        }
    };

    const addImages = () => {
        uploadedImages.forEach((item) => {
            if (item.file) {
                addImageLibrary(item.file, item.library_type)
                    .then(async (res) => {
                        if (res && res.statusCode === HttpStatusCode.Created) {
                            res.data.base64Image = item.base64Image;

                            setUploadedImages((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));

                            setTemplateImages((prevFiles) => [...prevFiles, res.data]);
                            toast.success("Create template successfully.");
                        }
                    })
                    .catch((e) => {
                        toast.warning("Server error occurred");
                    });
            } else {
                setUploadedImages((prevFiles) => prevFiles.filter((file) => file.preUploadId !== item.preUploadId));
            }
        });
    };

    const deleteImage = async (id: number) => {
        await deleteImageLibrary(id)
            .then((res) => {
                if (res) {
                    setTemplateImages((prevFiles) => prevFiles.filter((file) => file.id !== id));
                    toast.success(res.data);
                } else {
                    toast.warning("System Error");
                }
            })
            .catch((e) => toast.warning("Server error occured"));
    };

    const deleteUploadedImage = async (id: number) => {
        setUploadedImages((prevFiles) => prevFiles.filter((file) => file.preUploadId !== id));
        if (id) {
            toast.success("Delete Success.");
        }
    };

    const handleUploadImage = async (file: File) => {
        const maxId = Math.max(...uploadedImages.map((image) => image.preUploadId ?? 0));

        let model = new TemplateLibraryDto();
        model.preUploadId = maxId > 0 ? maxId + 1 : 1;
        model.file = file;
        model.isPreUploaded = true;

        model.base64Image = await fileToBase64(file);

        setUploadedImages((prevFiles) => [...prevFiles, model]);
    };

    const clearUploadedImage = () => {
        setUploadedImages([]);
    };

    const handleSelectImageToEdit = (image: TemplateLibraryDto | null, isEditImageAriaPage: boolean) => {
        if (!showLoader) {
            if (image !== null) {
                if (image.base64Image) {
                    setSelectedImageToEdit(image.base64Image);
                } else {
                    getOriginalTemplate(image.id);
                }
            } else {
                setSelectedImageToEdit(null);
            }
            setIsEditImageAriaPage(isEditImageAriaPage);
        }
    };

    const saveResultImage = (image: string | null) => {
        setResultImage(image);
    };

    return (
        <TemplateLibraryContext.Provider
            value={{
                getTemplates,
                getSixTemplates,
                templateImages,
                uploadedImages,
                addImages,
                deleteImage,
                handleUploadImage,
                handleSelectImageToEdit,
                selectedImageToEdit,
                clearUploadedImage,
                deleteUploadedImage,
                resultImage,
                saveResultImage,
                finalImageBase64,
                handleSetFinalImageBase64,
                setUploadedImages,
                isEditImageAriaPage,
                getOriginalTemplate,
            }}
        >
            {children}
        </TemplateLibraryContext.Provider>
    );
};

export const useTemplateLibrary = () => React.useContext(TemplateLibraryContext);

export interface imageHistory {
    scale: number;
    transform: string;
}
