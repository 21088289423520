import { useState } from "react";
import { Slider } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useGlobalActions } from "../../../../Context/useGlobalActionsContext";
import { useTemplateLibrary } from "../../../../Context/useTemplateLibrary";

type Props = {};

const ZoomSlider = () => {
    const { isEditImageAriaPage } = useTemplateLibrary();
    const [value, setValue] = useState(100);
    const { handleSetScale, scale } = useGlobalActions();

    const onChangeSlider = (val: number) => {
        if (isEditImageAriaPage) {
            setValue(val);
            handleSetScale(val / 100);
        }
    };

    return (
        <>
            <div className="flex w-full lg:w-44 justify-between gap-4 items-center rounded-none sm:rounded-lg custom-slider">
                <Slider
                    progress
                    style={{ width: "100%" }}
                    value={Math.round(scale * 100)}
                    onChange={(val) => {
                        onChangeSlider(val);
                    }}
                    max={300}
                />
                <span className="font-medium text-[12px] min-w-8 text-text_light_primary dark:text-text_dark_primary">{Math.round(scale * 100)}%</span>
            </div>
        </>
    );
};

export default ZoomSlider;
