import React, { useEffect } from "react";
import StickerManagement from "../../Components/AdminComponents/StickerManagement";
import ImageManagement from "../../Components/AdminComponents/ImageManagement";
import { useTemplateLibrary } from "../../Context/useTemplateLibrary";
import { useStickersLibrary } from "../../Context/useStickerLibrary";
import OtherManagement from "../../Components/AdminComponents/OtherManagement";
import TrumpManagement from "../../Components/AdminComponents/TrumpManagement";
import VoidManagement from "../../Components/AdminComponents/VoidManagement";

type Props = {};

const ManageLibrary = (props: Props) => {
    const { addImages, clearUploadedImage, getSixTemplates, getTemplates } = useTemplateLibrary();
    const stickersLibrary = useStickersLibrary();
    const handleUpload = async () => {
        addImages();
        stickersLibrary.addStickers();
        stickersLibrary.addOthers();
        stickersLibrary.addTrump();
        stickersLibrary.addVoid();
    };

    const handleCancel = async () => {
        clearUploadedImage();
        stickersLibrary.clearUploadedSticker();
    };

    useEffect(() => {
        getTemplates("memes");
        getSixTemplates("home-page-memes");

        getTemplates("wallpapers");
        getSixTemplates("home-page-wallpapers");

        getTemplates("banners");
        getSixTemplates("home-page-banners");
        stickersLibrary.getSixOthers();
        stickersLibrary.getOthers();
        stickersLibrary.getSixStickers();
        stickersLibrary.getStickers();

        stickersLibrary.getSixTrump();
        stickersLibrary.getTrump();

        stickersLibrary.getSixVoid();
        stickersLibrary.getVoid();
    }, []);

    return (
        <>
            <div className="w-full flex align-middle justify-center mt-8">
                <div className="flex flex-col justify-evenly w-full relative p-8 pb-4 gap-2">
                    <div className="sm:hidden font-black text-2xl font-inter">
                        <h1>SPARKY</h1>
                        <h1>MEME GENERATOR</h1>
                    </div>
                    <div className="flex justify-between w-full ">
                        <button onClick={handleCancel} className="h-10 text-base p-2 px-3 bg-button_primary hover:bg-buttonOrangeHover rounded-lg">
                            CANCEL
                        </button>
                        <button onClick={handleUpload} className="h-10 text-base p-2 px-3 bg-button_primary hover:bg-buttonOrangeHover rounded-lg">
                            SAVE CHANGES
                        </button>
                    </div>

                    <div className="flex flex-col w-full h-full p-3 rounded-lg gap-4 bg-text_light_secondary">
                        <TrumpManagement className=" " />
                        <VoidManagement className=" " />
                        <StickerManagement className=" " />
                        <OtherManagement className=" " />
                        <ImageManagement className=" " />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageLibrary;
