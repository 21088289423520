import LeftSide from "../../Components/LeftSide/LeftSide";
import LeftSideMobile from "../../Components/LeftSide/LeftSideMobile";
import MainArea from "../../Components/MainArea/MainArea";

const HomePage = () => {
    return (
        <>
            <div className="flex flex-col lg:flex-row pb-[72px] sm:pb-0 h-full lg:min-h-full">
                <LeftSide />
                <MainArea />
                <LeftSideMobile />
            </div>
        </>
    );
};

export default HomePage;
