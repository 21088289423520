import { useEffect, useRef, useState } from "react";
import FontsDropDown from "./FontsDropDown";
import { useTextsLibrary } from "../../../../Context/useTextLibrary";
import { AlignCenter, AlignLeft, AlignRight, Bold, Italic, Palette, Underline } from "../../../Common/Icons";
import ColorPickerStroke from "./ColorPickerStroke";

type Props = {
    closeTextEditor: () => void;
    buttonRef: React.RefObject<HTMLButtonElement>;
};

const TextEditor = ({ closeTextEditor, buttonRef }: Props) => {
    const { handleSetTextStyle, currentTextStyle } = useTextsLibrary();
    const textEditorRef = useRef<HTMLDivElement>(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showColorPickerStroke, setShowColorPickerStroke] = useState(false);
    const colorPickerRef = useRef<HTMLDivElement>(null);
    const colorPickerStrokeRef = useRef<HTMLDivElement>(null);
    const colorPickerButtonRef = useRef<HTMLButtonElement>(null);
    const colorPickerStrokeButtonRef = useRef<HTMLButtonElement>(null);
    const [strokeColor, setStrokeColor] = useState<string>("");
    const [textColor, setTextColor] = useState<string>("");

    const handleShowColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    };

    const handleShowColorPickerStroke = () => {
        setShowColorPickerStroke(!showColorPickerStroke);
    };

    const handleBold = () => {
        let current = currentTextStyle;
        if (current) {
            current.bold = !currentTextStyle?.bold;
            handleSetTextStyle(current);
        }
    };

    const handleItalic = () => {
        let current = currentTextStyle;
        if (current) {
            current.italic = !currentTextStyle?.italic;
            handleSetTextStyle(current);
        }
    };

    const handleUnderline = () => {
        let current = currentTextStyle;
        if (current) {
            current.underline = !currentTextStyle?.underline;
            handleSetTextStyle(current);
        }
    };

    const handleAlign = (align: string) => {
        let current = currentTextStyle;
        if (current) {
            current.align = align;
            handleSetTextStyle(current);
        }
    };

    const handleIsStroke = (event: React.ChangeEvent<HTMLInputElement>) => {
        let current = currentTextStyle;
        if (current) {
            current.stroke = event.target.checked;
            handleSetTextStyle(current);
        }
    };
    const [defColor, setDefColor] = useState("#000");
    const [defStrokeColor, setDefStrokeColor] = useState("#000");

    useEffect(() => {
        let current = currentTextStyle;
        if (current && !showColorPicker) {
            setDefColor(currentTextStyle?.color ?? "#000");
            setDefStrokeColor(currentTextStyle?.strokeColor ?? "#000");
        }
    }, [currentTextStyle]);

    useEffect(() => {
        let current = currentTextStyle;
        if (current) {
            current.strokeColor = strokeColor;
            handleSetTextStyle(current);
        }
    }, [strokeColor]);

    useEffect(() => {
        let current = currentTextStyle;
        if (current) {
            current.color = textColor;
            handleSetTextStyle(current);
        }
    }, [textColor]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (textEditorRef.current && !textEditorRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                closeTextEditor();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target) && colorPickerButtonRef.current && !colorPickerButtonRef.current.contains(event.target as Node)) {
                setShowColorPicker(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                colorPickerStrokeRef.current &&
                !colorPickerStrokeRef.current.contains(event.target) &&
                colorPickerStrokeButtonRef.current &&
                !colorPickerStrokeButtonRef.current.contains(event.target as Node)
            ) {
                setShowColorPickerStroke(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={textEditorRef} className="pt-6 pb-8 px-5 lg:p-4 rounded-t-3xl lg:rounded-lg bg-bg_light_primary dark:bg-bg_dark_third border border-modal_light_border dark:border-none">
                <div className="flex flex-col gap-2">
                    <div className=" flex lg:hidden justify-between items-center">
                        <p className="font-black text-lg text-text_light_secondary dark:text-white  uppercase">Font</p>
                        <svg onClick={closeTextEditor} width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-text_light_secondary dark:text-white">
                            <path d="M11 1L1 11M1 1L11 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="relative flex items-center justify-center gap-2">
                        <FontsDropDown />

                        <button
                            className="flex gap-2 justify-center items-center bg-white dark:bg-bg_dark_secondary border border-modal_light_border dark:border-bg_dark_secondary  rounded-lg py-2 px-4"
                            ref={colorPickerButtonRef}
                            onClick={handleShowColorPicker}
                        >
                            <Palette color="text-text_light_primary dark:text-text_dark_primary" />
                            <div className="w-[49px] h-6 rounded-md" style={{ backgroundColor: textColor }}></div>
                        </button>
                        <div ref={colorPickerRef} className={`absolute bottom-10 lg:top-14 right-0 z-50 ${showColorPicker ? "" : "hidden"}`}>
                            <ColorPickerStroke setStrokeColor={setTextColor} colorDefault={defColor} />
                        </div>
                    </div>
                    <div className="flex gap-2 w-full">
                        <div className="flex p-1 justify-between items-center w-full bg-white dark:bg-bg_dark_secondary border border-modal_light_border dark:border-bg_dark_secondary rounded-lg">
                            <button onClick={() => handleAlign("LEFT")} className={`rounded-md py-2 px-3 lg:px-4 ${currentTextStyle?.align === "LEFT" ? "bg-hr_light" : ""}`}>
                                <AlignLeft color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>

                            <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none lg:mx-[2px]" />
                            <button onClick={() => handleAlign("CENTER")} className={`rounded-md py-2 px-3 lg:px-4 ${currentTextStyle?.align === "CENTER" ? "bg-hr_light" : ""}`}>
                                <AlignCenter color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>

                            <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none lg:mx-[2px]" />
                            <button onClick={() => handleAlign("RIGHT")} className={`rounded-md py-2 px-3 lg:px-4 ${currentTextStyle?.align === "RIGHT" ? "bg-hr_light" : ""}`}>
                                <AlignRight color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                        </div>
                        <div className="flex p-1 justify-between items-center w-full bg-white dark:bg-bg_dark_secondary border border-modal_light_border dark:border-bg_dark_secondary rounded-lg">
                            <button onClick={handleBold} className={`rounded-md py-2 px-3 lg:px-4 ${currentTextStyle?.bold ? "bg-hr_light" : ""}`}>
                                <Bold color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none lg:mx-[2px]" />
                            <button onClick={handleItalic} className={`rounded-md py-2 px-3 lg:px-4 ${currentTextStyle?.italic ? "bg-hr_light" : ""}`}>
                                <Italic color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                            <hr className="h-6 w-px bg-hr_light dark:bg-hr_dark border-none lg:mx-[2px]" />
                            <button onClick={handleUnderline} className={`rounded-md py-2 px-3 lg:px-4 ${currentTextStyle?.underline ? "bg-hr_light" : ""}`}>
                                <Underline color="text-text_light_primary dark:text-text_dark_primary" />
                            </button>
                        </div>
                    </div>
                    <div className="relative flex justify-between items-center w-full py-2 px-4 bg-white dark:bg-bg_dark_secondary border border-modal_light_border dark:border-bg_dark_secondary rounded-lg">
                        <div className="flex justify-start items-center gap-2">
                            <input
                                type="checkbox"
                                className="w-5 h-5 text-hr_light border-2 border-hr_light focus:ring-0 focus:ring-offset-0 rounded "
                                checked={currentTextStyle?.stroke}
                                onChange={handleIsStroke}
                            />
                            <span className="text-base font-medium text-text_light_primary dark:text-text_dark_primary">STROKE</span>
                        </div>
                        <button
                            ref={colorPickerStrokeButtonRef}
                            onClick={handleShowColorPickerStroke}
                            className="w-[49px] h-6 rounded-md border border-modal_light_border "
                            style={{ backgroundColor: strokeColor }}
                        ></button>
                        <div ref={colorPickerStrokeRef} className={`absolute bottom-10 lg:top-10 right-0 z-50 ${showColorPickerStroke ? "" : "hidden"}`}>
                            <ColorPickerStroke colorDefault={defStrokeColor} setStrokeColor={setStrokeColor} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TextEditor;
